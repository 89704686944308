import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpClientModule } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap, map, isEmpty } from 'rxjs/operators';
import { IIwaApplication } from '../components/models/iwa-application.model';
import { BuildEnvironment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ApplicationListService {
  private baseurl = BuildEnvironment.GetEnvironmentUrl() + '/applications';
  private applicationList: any[];

  constructor(private http: HttpClient) { }

  public getAllApplications() {
    // console.log('application-list.service.getAllApplications()');
    let bindUrl: string = this.baseurl + '/allapplications?appStatus=submitted&appStep=9';
    return this.http.get(bindUrl);
  }
  public getAllApplicationsList() {
    console.log('application-list.service.getAllApplicationsList()');
    let bindUrl: string = this.baseurl + '/allapplicationslist?appStatus=submitted&appStep=9';
    return this.http.get(bindUrl);
  }


  getFilteredApplicationsId(applicationId: string) {
    let bindUrl: string = this.baseurl + '/allfilteredapplications/' + applicationId;
    return this.http.get(bindUrl);
  }
  getFilteredApplicationsStatTerm(appStatus: string, appTerm: string) {
    // console.log('application-list.service.getFilteredApplicationsStatTerm()');
    let bindUrl: string;
    // let bindUrl: string = this.baseurl + '/allfilteredapplications/' + appStatus + '/' + appTerm;
    if (appStatus !== null && appStatus !== '' && appStatus.length !== 0 && appTerm !== null && appTerm !== '' && appTerm.length !== 0) {
      bindUrl = this.baseurl + '/allfilteredapplications/appStatus=' + appStatus + '/appTerm=' + appTerm;
    } else if ((appStatus === null || appStatus === '' || appStatus.length === 0) && appTerm !== null && appTerm !== '' && appTerm.length !== 0) {
      bindUrl = this.baseurl + '/allfilteredapplications/appTerm=' + appTerm;
    } else if (appStatus !== null && appStatus !== '' && appStatus.length !== 0 && (appTerm === null || appTerm === '' || appTerm.length === 0)) {
      bindUrl = this.baseurl + '/allfilteredapplications/appStatus=' + appStatus;
    }
    // console.log('Req AppStatus: >' + (appStatus === null ? 'NULL' : appStatus === '' ? 'Empty String' : appStatus.trim().length === 0 ? 'Zero Len' : appStatus) + ', Term: ' + appTerm);
    // console.log('BindUrl: ' + bindUrl);
    return this.http.get(bindUrl);
  }
  getApplicationById(applicationId: string) {
    return this.http.get(this.baseurl + '/getapplication/' + applicationId);
  }
  getApplicationFiles(applicationId: string) {
    return this.http.get(this.baseurl + '/getapplicationfiles/' + applicationId);
  }
  getFileContent(applicationId: string, fileId: string) {
    return this.http.get(this.baseurl + '/getfiledetails/' + applicationId + "/" + fileId);
  }
  getApplicationProgChoices(applicationId: string) {
    return this.http.get(this.baseurl + '/getapplicationprogchoices/' + applicationId);
  }

  updateApplicationStatus(id, status) {
    return this.http.post(this.baseurl + '/updateapplicationstatus', {appId: id, status});
  }
  getApplicationStatusList() {
    return this.http.get(this.baseurl + '/getapplicationstatuslist');
  }
  submitAppToPS(applicationId: string){
    return this.http.post(this.baseurl + '/submitSingleAppToCS', {applicationId: applicationId});
  }

  submitDocsToPS(applicationId: string){
    return this.http.post(this.baseurl + '/submitSingleApplDocsToStorage', {applicationId: applicationId});
  }

  markApplicationAsPaidWithPromoCode(applicationId: string, promoCode: string){
    return this.http.post(this.baseurl + '/markApplicationAsPaidWithPromoCode', {applicationId: applicationId, promoCode: promoCode});
  }
}
