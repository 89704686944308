
<div class="content-container">
  <mat-card id="main-mat-card">
    <mat-card-title>
      <h2 class="card-title">Current Application Fee</h2>
    </mat-card-title>
    <div class="mat-content">
      <form #iwaFeeForm="ngForm" [formGroup]="IwaFeeForm" (ngSubmit)="updateFee(IwaFeeForm.value)" class="form-container">

        <mat-form-field id="fee" appearance="outline">
          <mat-label>Fee</mat-label>
          <input matInput placeholder="Fee" formControlName="iwaApplFee" value="{{ iwaFeeData.ApplFee }}" required />
            <mat-error> <em
                *ngIf="IwaFeeForm.controls.iwaApplFee?.touched && IwaFeeForm.controls.iwaApplFee?.invalid">
                Required</em> </mat-error>
        </mat-form-field>

        <div class="actions-box">
          <mat-card-actions>
            <button mat-stroked-button type="button" class="seneca-button red" color="primary" (click)="goHome()">
              <span>Cancel</span>
            </button>

              <button type="submit" mat-flat-button class="seneca-button"
                color="primary">
                <span>Update</span>
              </button>
          </mat-card-actions>
        </div>
        <input matInput placeholder="UpdatedBy" formControlName="UpdatedBy" value="{{ currentUser }}" hidden />
      </form>
      <mat-card-content class="mat-elevation-z8"> </mat-card-content>
    </div>
  </mat-card>

  <br />
<!-- TODO replace with dynamic data -->
<p class="bottom_p"><span class="bold-text">Last Modified By:</span> {{ iwaFeeData.UpdatedBy }} on {{ iwaFeeData.UpdatedDate }}</p>

</div>
