import { Component, HostListener, OnDestroy, Input, ViewChild, ComponentFactoryResolver, SimpleChanges, Output } from '@angular/core';
import { MatSort, MatPaginator, MatTableDataSource, Sort } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApplicationListService } from '../../../../services/application-list.service';
import { IIwaApplication } from '../../../models/iwa-application.model';
import { FileContentComponent } from '../file-content/file-content.component';
import { IApplicationChoice, ApplicationProgChoice } from '../../../models/application-choice';
import { Binary } from '@angular/compiler';
import { ApplicationFileContentService } from '../../../../services/application-file-content.service';
//import { SimplePdfViewerComponent } from 'simple-pdf-viewer';
import { DialogService } from '../../../../services/dialog.service';
//import { EventEmitter } from 'events';
import { EventEmitter } from '@angular/core';

export interface DialogData {
  applicationId: string;
  fileId: string;
  fileType: string;
  fileContent: Binary;
  fileReader: FileReader;
  fileContentBase64: string;
}

@Component({
  selector: 'app-view-application',
  templateUrl: './view-application.component.html',
  styleUrls: ['../../../../../content/shared/css/commonList.css', './view-application.component.scss', '../../applications.component.scss']
})
export class ViewApplicationComponent {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('pdfViewer') pdfViewer: any;
  // @ViewChild(SimplePdfViewerComponent) private pdfViewer: SimplePdfViewerComponent;

  @Input() applicationNum: IIwaApplication;
  @Input() status: string[];
  @Output("loadRequestedApplication") reloadRequestedApplication: EventEmitter<any> = new EventEmitter();

  // @Input() applicationDetails;
  // public dialog: MatDialog;
  selectedStatus: string;
  oneApplicationDetails: IIwaApplication;
  public applicationFiles: any[];
  public applicationProgChoices: any[];
  ProgChoiceMessage: string;
  public aplicationFilesFound: boolean;
  ELEMENT_DATA: any[];
  filecontent: any;
  fileId: string;
  isImageLoading: boolean;
  imageContent: string;

  psBtnDisabled: boolean = false;
  psDocsBtnDisabled: boolean = false;

  displayedColumns: string[] = ['idx', 'documentType', 'loadDate', 'fieldLocation', 'fileName'];
  dataSource: MatTableDataSource<any>;
  ProgChoiceColumns: string[] = ['choiceNumber', 'term', 'startDate', 'progCode', 'progCampus', 'actionReason'];


  constructor(public dialog: MatDialog, 
    private service: ApplicationListService,
    private dialogService: DialogService,
    private contentService: ApplicationFileContentService) { }


  openDialog(appId: string, selectFileId: string, filetype: string, fileReader: FileReader, imagecontentBase64: string) {
    // console.log('openDialog AppId: ' + appId + ', FileID: ' + selectFileId);
    let imageData: any = null;

    var dialogRef = this.dialog.open(FileContentComponent, {
      width: '800px',
      data: { applicationId: appId, fileId: selectFileId, fileType: filetype, fileContent: this.filecontent, fileReader: fileReader, fileContentBase64: this.imageContent }
    });
  }
  submitAppToPS(appId: string){
    this.psBtnDisabled = true;
    this.service.submitAppToPS(appId).subscribe(res => {
      this.dialogService.MessageBox("Application " + appId + " submitted ", 'X', 5000,'SuccessMessage' );
      this.reloadRequestedApplication.emit(appId);
      console.log("view-application.component  reload the App details");
      this.psBtnDisabled = false;
    });
  }

  submitDocsToPS(appId: string){
    this.psDocsBtnDisabled = true;
    interface resObj {
      message: string;
      error: string;
    };
    this.service.submitDocsToPS(appId).subscribe((res: resObj) => {
      if(res.error){
        this.dialogService.MessageBox('No Documents were pushed', 'X', 5000,'ErrorMessage' );
      }
      else{
        this.dialogService.MessageBox(res.message, 'X', 5000,'SuccessMessage' );
      }
      this.psDocsBtnDisabled = false;
    });
  }

  saveApplication(status, appId: string): void{
    interface resObj {
      message: string;
      error: string;
    };

    this.service.updateApplicationStatus(appId, status.value).subscribe((res: resObj) => {
      if(res.error){
        this.dialogService.MessageBox('Error: '+ res.error, 'X', 5000, 'ErrorMessage');
      }
      else{
        this.dialogService.MessageBox(res.message, 'X', 5000,'SuccessMessage' );
        this.reloadRequestedApplication.emit(appId);
      }
    });
  }

  markApplicationAsPaid(appId: string, promoCode: string){
    interface resObj {
      message: string;
      error: string;
    };
    this.service.markApplicationAsPaidWithPromoCode(appId, promoCode).subscribe((res: resObj) => {
      if(res.error){
        this.dialogService.MessageBox('Not marked as paid. ' + res.error, 'X', 5000,'ErrorMessage' );
      }
      else{
        this.dialogService.MessageBox(res.message, 'X', 5000,'SuccessMessage' );
        this.reloadRequestedApplication.emit(appId);
      }
      
    });
  }

  getFileContent(appId: string, selectedFileId: string, fileType: string) {
    this.isImageLoading = true;
    this.contentService.getFileContent(appId, selectedFileId, fileType).subscribe(imgData => {
      // OK console.log(imgData);
      if (fileType !== 'pdf') {
        // if (true) {
        this.createImageFromBlob(appId, selectedFileId, fileType, imgData);
      } else {
        this.filecontent = imgData;
        var arrayBuffer;
        let fileReader: FileReader = new FileReader();
        fileReader.readAsDataURL(imgData);
        // fileReader.onload = () => {
        fileReader.addEventListener("load", () => {
          // arrayBuffer = fileReader.result;
          //this.filecontent = imgData;
          this.imageContent = fileReader.result.toString();
          this.openDialog(appId, selectedFileId, fileType, fileReader, this.imageContent);

        });
      }
      this.isImageLoading = false;
    }, error => {
      this.isImageLoading = false;
      console.log(error);
    });
  }
  createImageFromBlob(appId: string, selectedFileId: string, fileType: string, image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      //console.log(reader.result);
      this.filecontent = (reader.result).toString();
      // console.log(this.filecontent);
      this.openDialog(appId, selectedFileId, fileType, null, null);
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }

}
