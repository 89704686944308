<div class="seneca-list-container">
  <mat-card class="main-container">
    <mat-card-title>
      <h1 class="card-title">Academic Programs</h1>
      <div class="actions-box">
        <mat-form-field appearance="outline" class="search-bar">
          <mat-label>Term</mat-label>
          <input matInput #search maxlength="4" placeholder="Term" />
          <mat-hint align="start">Four digits </mat-hint>
        </mat-form-field>

        <mat-card-actions>
          <button mat-stroked-button class="seneca-button seneca-stroke-button" color="primary"
          (click)="loadPrograms(search.value, 1)" [disabled] = "!isIWAAdmin">
            Refresh from PeopleSoft
          </button>
          <button mat-flat-button type="button" class="seneca-button" color="primary"
            (click)="loadPrograms(search.value, 2)">
            Load from IWA Database
          </button>
        </mat-card-actions>
      </div>
    </mat-card-title>
  </mat-card>
</div>
<app-academic-programs-list *ngIf="loadProgram" [term]="term"></app-academic-programs-list>