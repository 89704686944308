import { Component, OnInit, Inject, OnChanges } from '@angular/core';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { MatGridList, MatGridTile } from '@angular/material';
import { DialogData } from '../view-application/view-application.component';
// import { ApplicationFileContentService } from '../../../../services/application-file-content.service';

// import { PdfJsViewerComponent, PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { FileMimeType } from '@taldor-ltd/angular-file-viewer';
// import { NgxExtendedPdfViewerModule  } from'ngx-extended-pdf-viewer';
// import {
//   LinkAnnotationService, BookmarkViewService, MagnificationService, ThumbnailViewService,
//   ToolbarService, NavigationService, TextSearchService, TextSelectionService, PrintService
// } from '@syncfusion/ej2-angular-pdfviewer';


@Pipe({
  name: 'safeHtml'
})

@Component({
  selector: 'app-file-content',
  templateUrl: './file-content.component.html',
  styleUrls: ['./file-content.component.scss']
  // ,
  // providers: [LinkAnnotationService, MagnificationService,
  //   ThumbnailViewService, NavigationService, TextSearchService, TextSelectionService, PrintService]
})

export class FileContentComponent implements OnInit, PipeTransform {
  applicationId: string;
  fileId: string;
  //fileContent: any;
  isImageLoading: boolean;
  pdfUrl: any;
  documentType: string;
  // public service = 'https://ej2services.syncfusion.com/production/web-services/api/pdfviewer';

  constructor(public dialogRef: MatDialogRef<FileContentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private sanitizer: DomSanitizer
  ) { }

  transform(value: any, ...args: any[]) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

  ngOnInit() {
    console.log('FileContentComponent ngOnInit()');
    //console.log(this.data.fileContent);
    // this.pdfUrl = 'http://localhost:3000/public/controllers/api/applications/getfiledetails/56000013874/passport/pdf';
    if (this.data.fileType !== 'pdf') {
      this.transform(this.data.fileContent);
    } else {
      //this.transform(this.data.fileContentBase64);
      this.documentType = FileMimeType.PDF;
      /*
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(this.data.fileContentBase64);
        return;
      }
      */

      // window.navigator.msSaveOrOpenBlob(this.data.fileContent);
    }

    //this.readPdfDoc();
    // if (this.data.fileType !== 'pdf') {
    //   this.pdfUrl = this.data.fileContent;
    // }
    // this.pdfUrl = this.data.fileReader;
  }
  // this.getFileContent(this.data.applicationId, this.data.fileId, this.data.fileType);

  // downloadFile(request: string, fileName: string): any {
  //   // return this.resourceService.getFile(request + '/' + fileName, null);
  //   return this.contentService.getFileContent(appId, selectedFileId, fileType).subscribe(imgData => {
  //     const newBlob = new Blob([imgData], { type: response.type });

  //     // IE doesn't allow using a blob object directly as link href
  //     // instead it is necessary to use msSaveOrOpenBlob
  //     if (window.navigator && window.navigator.msSaveOrOpenBlob) {
  //       window.navigator.msSaveOrOpenBlob(newBlob);
  //       return;
  //     }

  //     const uploadedFile = new File([response], fileName, { type: response.type });
  //     if (uploadedFile.name !== '') {
  //       this.files.add(uploadedFile);
  //   });
  // }
  /*
  readPdfDoc() {
    if (typeof (FileReader) !== 'undefined') {
      let reader = new FileReader();

      reader.onload = (e: any) => {
        this.pdfUrl = e.target.result;
      };
      let byteNumbers = new Array(this.data.fileContent.length);
      for (let i = 0; i < this.data.fileContent.length; i++) {
        byteNumbers[i] = this.data.fileContent.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      let blob = new Blob([byteArray], { type: 'application/octet-stream' });

      reader.readAsArrayBuffer(blob);
    }
  }
  /*
  createImageFromBase64(imageData: string) {
    this.data.fileContent = 'data:image/png;base64,' + this.hexToBase64(imageData);
  }
  hexToBase64(str) {
    return btoa(String.fromCharCode.apply(null, str.replace(/\r|\n/g, "").replace(/([\da-fA-F]{2}) ?/g, "0x$1 ").replace(/ +$/, "").split(" ")));
  }

  // tslint:disable-next-line:use-life-cycle-interface
  // ngOnChanges() {
  //   this.getFileContent(this.data.applicationId, this.data.fileId, this.data.fileType);
  // }
  onNoClick(): void {
    this.dialogRef.close();
  }
  // getFileContent(appId: string, selectedFileId: string, fileType: string) {
  //   this.isImageLoading = true;
  //   console.log('File-Content Component getFileContent() AppId: ' + appId + ', FileId: ' + selectedFileId);
  //   this.service.getFileContent(appId, selectedFileId, fileType).subscribe(binData => {
  //     this.createImageFromBase64(binData.toString());
  //     // this.fileContent = binData;
  //     this.isImageLoading = false;
  //   }, error => {
  //     this.isImageLoading = false;
  //     console.log(error);
  //   });
  // }
  /*
  getFileContent(appId: string, selectedFileId: string, fileType: string) {
    this.isImageLoading = true;
    this.service.getFileContent(appId, selectedFileId, fileType).subscribe(imgData => {
      this.createImageFromBlob(imgData);
      this.isImageLoading = false;
    }, error => {
      this.isImageLoading = false;
      console.log(error);
    });
  }
  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      //console.log(reader.result);
      this.fileContent = this.transform(reader.result);
      //console.log(reader.result);

    }, false);
    if (image) {
      reader.readAsDataURL(image);
      // reader.onload = (_event) => {
      //   this.fileContent = this.transform(reader.result);
      // }
      //console.log(image);
    }
  }
  */
  // createImageFromBase64(imageData: string) {
  //   this.fileContent = 'data:image/png;base64,' + this.hexToBase64(imageData);
  // }
}
