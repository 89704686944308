import { NgModule } from '@angular/core';
import { MaterialModule } from '../shared/material.module';
import { LayoutModule } from '@angular/cdk/layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MainNavComponent } from './components/main-nav/main-nav.component';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { RouterModule } from '@angular/router';
import { GravatarModule } from 'ngx-gravatar';
import { HttpCommonService } from './services/http-common.service';
import { SnackbarSuccessComponent } from './components/snackbar-success/snackbar-success.component';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material';
import { BackToTopComponent } from '../shared/components/back-to-top/back-to-top.component';
import { IconSnackBarComponent } from "./components/icon-snack-bar/icon-snack-bar.component";
import { LogoutComponent } from './components/logout/logout.component';
import { ProgressSpinnerComponent } from './components/progress-spinner/progress-spinner.component';

@NgModule({    
    declarations: [MainNavComponent, SnackbarSuccessComponent, LogoutComponent, BackToTopComponent, IconSnackBarComponent, ProgressSpinnerComponent],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    LayoutModule,
    FormsModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    HttpClientModule,
    ScrollingModule,
    GravatarModule
  ],
  providers: [
    HttpCommonService,
    { provide: MAT_SNACK_BAR_DATA, useValue: {} },
    { provide: MatSnackBarRef, useValue: {} },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3000, verticalPosition: 'top' } }
  ],
  entryComponents: [SnackbarSuccessComponent, BackToTopComponent, IconSnackBarComponent],
  exports: [
    MainNavComponent,
    CommonModule,
    MaterialModule,
    LayoutModule,
    FormsModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    HttpClientModule,
    ScrollingModule,
    GravatarModule,
    SnackbarSuccessComponent,
    BackToTopComponent,
    LogoutComponent,
    ProgressSpinnerComponent
  ]
})
export class SharedModule {}
