<div class="seneca-list-container">
  <mat-card class="main-container">
    <mat-card-title>
      <h2 class="card-title">Term: {{term}}</h2>

    </mat-card-title>

    <mat-card-content>

      <div class="actions-box">
        <mat-card-actions>
          <mat-form-field id="status" appearance="outline">
            <mat-label>Select Bulk Status</mat-label>
            <mat-select [(value)]="selectedBulkOption" [disabled] = "!isIWAAdmin">

                <mat-option *ngFor="let optionSel of bulkStatuses" [value]="optionSel.OptionKey">
                  {{optionSel.OptionValue}}
                </mat-option>
            </mat-select>
          </mat-form-field>
          <!--<button mat-stroked-button class="seneca-button seneca-stroke-button" color="primary">
            <span>Update</span>
          </button>-->
          <button mat-flat-button class="seneca-button" color="primary"
          (click)="bulkPublish()" [disabled] = "!isIWAAdmin">
            <mat-icon>publish</mat-icon> <span>Update Status</span>
          </button>
        </mat-card-actions>

        <mat-card-actions>
          <mat-form-field id="expgradterm" appearance="outline">
            <mat-label>Select Exp Grad Term</mat-label>
            <input matInput [(ngModel)]="selectedGradTerm" maxlength="4">
          </mat-form-field>

          <button mat-flat-button class="seneca-button" color="primary"
          (click)="expGradTermPublish()" [disabled] = "!isIWAAdmin">
            <mat-icon>publish</mat-icon> <span>Update Grad Term</span>
          </button>
        </mat-card-actions>

        <mat-form-field appearance="outline" class="search-bar">
          <mat-label>Search</mat-label>
          <input matInput #search (keyup)="applyFilter($event.target.value)" maxlength="256" placeholder="Search" />
          <mat-icon matSuffix>search</mat-icon>

          <mat-hint align="start">Example: Campus, Code </mat-hint>
        </mat-form-field>
      </div>
      <div class="seneca-table-container seneca-elevation">
        <table mat-table [dataSource]="dataSource" matSort>
          <!-- Checkbox Column -->
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="$event ? masterToggle() : null"
                [checked]="selectionHasValue() && isAllSelected()"
                [indeterminate]="selectionHasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation();"
                (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"
                [aria-label]="checkboxLabel(row)">
              </mat-checkbox>
            </td>
          </ng-container>
          <!--- Note that these columns can be defined in any order.
                        The actual rendered columns are set as a property on the row definition" -->

          <ng-container matColumnDef="PROG_DESCR" class="mat-column-PROG_DESCR">
            <th class="priority-1" mat-header-cell *matHeaderCellDef mat-sort-header>Program Name</th>
            <td class="priority-1" mat-cell *matCellDef="let element">{{ element.PROG_DESCR }}</td>
          </ng-container>
          <ng-container matColumnDef="ACAD_PROG" class="mat-column-ACAD_PROG">
            <th class="priority-2" mat-header-cell *matHeaderCellDef mat-sort-header>Code</th>
            <td class="priority-2" mat-cell *matCellDef="let element">{{element.ACAD_PROG}}
            </td>
          </ng-container>
          <ng-container matColumnDef="CAMPUS_DESC" class="mat-column-CAMPUS_DESC">
            <th class="priority-3" mat-header-cell *matHeaderCellDef mat-sort-header>Campus</th>
            <td class="priority-3" mat-cell *matCellDef="let element">{{ element.CAMPUS_DESC }}</td>
          </ng-container>

          <ng-container matColumnDef="DEGREE" class="mat-column-DEGREE">
            <th class="priority-4" mat-header-cell *matHeaderCellDef mat-sort-header>Credential</th>
            <td class="priority-4" mat-cell *matCellDef="let element">{{getCred(element.DEGREE)}}
            </td>
          </ng-container>

          <ng-container matColumnDef="PROG_LEN" class="mat-column-PROG_LEN">
            <th class="priority-5" mat-header-cell *matHeaderCellDef mat-sort-header>Semester</th>
            <td class="priority-5" mat-cell *matCellDef="let element">{{ element.PROG_LEN }}</td>
          </ng-container>
          
          <ng-container matColumnDef="EXP_GRAD_TERM" class="mat-column-EXP_GRAD_TERM">
            <th class="priority-11" mat-header-cell *matHeaderCellDef mat-sort-header>Exp Grad Term</th>
            <td class="priority-11" mat-cell *matCellDef="let element">
              <span *ngIf="element.EXP_GRAD_TERM != null">{{ element.EXP_GRAD_TERM }}</span>
              <span *ngIf="element.EXP_GRAD_TERM == null">N/A</span>
            </td>
          </ng-container>

          <!-- Symbol Column -->
          <!-- <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
          <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container> -->
          <ng-container matColumnDef="PROG_AVAILABILITY" class="mat-column-PROG_AVAILABILITY">
            <th class="priority-6" mat-header-cell *matHeaderCellDef mat-sort-header>Availablity</th>
            <td class="priority-6" mat-cell *matCellDef="let element">
              <span class="dot" [ngClass]="{
              'dot-publish': element.PROG_AVAILABILITY == 'OP',
              'dot-closed': element.PROG_AVAILABILITY == 'CL',
              'dot-waitlist': element.PROG_AVAILABILITY == 'WL',
              'dot-cancelled': element.PROG_AVAILABILITY == 'CN',
              'dot-suspended': element.PROG_AVAILABILITY == 'SU',
              'dot-draft':element.PROG_AVAILABILITY == 'INS'
            }">
              </span>
              {{ getStatus(element.PROG_AVAILABILITY) }}
            </td>
          </ng-container>
          <ng-container matColumnDef="ELI_CONDITIONAL" class="mat-column-ELI_CONDITIONAL">
            <th class="priority-7" mat-header-cell *matHeaderCellDef mat-sort-header>ELI</th>
            <td class="priority-7" mat-cell *matCellDef="let element">
              <span class="dot" [ngClass]="{
              'dot-draft': element.ELI_CONDITIONAL == 'Y',
              'dot-closed': element.ELI_CONDITIONAL == 'N'
            }">
              </span>
              {{element.ELI_CONDITIONAL === 'Y' ? 'Yes' : 'No'}}
            </td>
          </ng-container>
          <ng-container matColumnDef="PATHWAY_CONDITIONAL" class="mat-column-PATHWAY_CONDITIONAL">
            <th class="priority-8" mat-header-cell *matHeaderCellDef mat-sort-header>Pathway</th>
            <td class="priority-8" mat-cell *matCellDef="let element">
              <span class="dot" [ngClass]="{
              'dot-draft': element.PATHWAY_CONDITIONAL == 'Y',
              'dot-closed': element.PATHWAY_CONDITIONAL == 'N'
            }">
              </span>
              {{element.PATHWAY_CONDITIONAL === 'Y' ? 'Yes' : 'No'}}
            </td>
          </ng-container>
          <ng-container matColumnDef="BUNDLE_ELIGIBLE" class="mat-column-BUNDLE_ELIGIBLE">
            <th class="priority-9" mat-header-cell *matHeaderCellDef mat-sort-header>Bundle</th>
            <td class="priority-9" mat-cell *matCellDef="let element">
              <span class="dot" [ngClass]="{
                'dot-draft': element.BUNDLE_ELIGIBLE == 'Y',
                'dot-closed': element.BUNDLE_ELIGIBLE == 'N'
              }">
                </span>
              {{ element.BUNDLE_ELIGIBLE === 'Y' ? 'Yes' : 'No'}}</td>
          </ng-container>
          <ng-container matColumnDef="DEGREE_BRIDGE" class="mat-column-DEGREE_BRIDGE">
            <th class="priority-10" mat-header-cell *matHeaderCellDef mat-sort-header>Degree Bridge</th>
            <td class="priority-10" mat-cell *matCellDef="let element">
              <span class="dot" [ngClass]="{
              'dot-draft': element.DEGREE_BRIDGE == 'Y',
              'dot-closed': element.DEGREE_BRIDGE == 'N' || element.DEGREE_BRIDGE == null
            }">
              </span>
              {{element.DEGREE_BRIDGE === 'Y' ? 'Yes' : 'No'}}
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[5, 10, 20, 50, 100]" showFirstLastButtons> </mat-paginator>
      </div>
    </mat-card-content>
  </mat-card>
  <!--<h1 mat-dialog-title>Confirm Action</h1>
  <div mat-dialog-content>
    <p>Are you sure you want to apply these changes?</p>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="dialogRef.close(true)">Confirm</button>
    <button mat-button (click)="dialogRef.close(false)">Cancel</button>
  </div>-->
</div>
<div *ngIf="programsFromCSLoading" class="loading-indicator">
  <mat-spinner diameter="100" strokeWidth="15"></mat-spinner>  
</div>