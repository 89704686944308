import { FormGroup, FormBuilder, FormControl, Form } from '@angular/forms';
import { Component, OnInit, ComponentFactoryResolver, ViewChild, ViewContainerRef } from '@angular/core';
//---------dialog------------------/
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from '../../../content/services/dialog.service';


//---------service--------//
import { ApplicationListService } from '../../services/application-list.service';
import { ViewApplicationComponent } from './application-results/view-application/view-application.component';
import { IIwaApplication, IApplicationId } from '../models/iwa-application.model';
import { Router } from '@angular/router';
import { ApplicationFileContentService } from '../../services/application-file-content.service';

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['../../../content/shared/css/commonList.css', './applications.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialogService]
})
export class ApplicationsComponent implements OnInit {
  @ViewChild('vc', { read: ViewContainerRef }) _container: ViewContainerRef;
  status = [{ id: 1, value: 'Ready to be Submitted' }, { id: 2, value: 'Not Ready to be Submitted' }];
  submitStatus = [{ id: 1, value: 'Error On Submit' }];

  loadApplication: boolean;
  application: IIwaApplication;
  applicationList: IApplicationId[];
  SearchForm: FormGroup;
  cmpFactory: any;
  // dataSource: MatTableDataSource<any>;

  constructor(
    private _formBuilder: FormBuilder,
    private service: ApplicationListService,
    public dialog: MatDialog,
    private dialogService: DialogService,
    private router: Router,
    private _resolver: ComponentFactoryResolver) {
  }

  ngOnInit() {
    this.loadApplication = true;
    this.SearchForm = this._formBuilder.group({
      appNumber: ['', null],
      appStatus: ['', null],
      appTerm: ['', null],
      appSubmitStatus: ['', null]
    });
  }
  // ngAfterViewInit() {
  //   console.log('ngAfterViewInit()');
  //   this.cmpFactory = this._resolver.resolveComponentFactory(ApplicationResultsComponent);
  //   this._container.createComponent(this.cmpFactory);
  // }

  loadAllApplications() {
    // this.service.getAllApplications().subscribe((applicationListData: IIwaApplication[]) => {
    //   this.applicationList = applicationListData;
    // });
    console.log('applications.component loadAllApplications() called');
    this.service.getAllApplicationsList().subscribe((applicationListData: IApplicationId[]) => {
      this.applicationList = applicationListData;
    });
  }
  loadApplications() {
    console.log('loadApplications()');
    this.loadApplication = true;
    this.service.getAllApplications();
    //this.application = applicationValue;
    // console.log(applicationValue);
  }
  searchApplication(filterValue: any) {
    console.log('Clicked "Search Button"');
    console.log('Search App Number: ' + this.SearchForm.value.appNumber);
    if (this.SearchForm.value.appNumber === '' &&
      this.SearchForm.value.appSubmitStatus === '' &&
      this.SearchForm.value.appStatus === '' &&
      this.SearchForm.value.appTerm === '') {
      alert('Pleas eselect a search criteria.');
    } else {
      if (this.SearchForm.value.appNumber !== '') {
        let dialog: MatDialog;
        let contentService: ApplicationFileContentService;
        let appDetails = new ViewApplicationComponent(dialog, this.service,null, contentService);
        // appDetails.searchButtonClicked = true;
        appDetails.applicationNum = this.SearchForm.value.appNumber;
        // console.log('Creating a new View-Application component: ' + this.SearchForm.value.appNumber);
        // appDetails.ngOnChanges(this.SearchForm.value.appNumber);
        //appDetails.
        // let appResult = this._resolver.resolveComponentFactory(ApplicationResultsComponent);
        // let ref = this._container.createComponent(appDetails);
        // this.cmpFactory = this._resolver.resolveComponentFactory(ViewApplicationComponent);
        // this._container.remove();
        // let ref = this._container.createComponent(this.cmpFactory);
        // ref.instance.applicationNum = this.SearchForm.value.appNumber


        // tslint:disable-next-line: deprecation
        // ref.injector.get(this.SearchForm.value.appNumber);
        // ref.changeDetectorRef.detectChanges();
        //let appResultList = ref.getFilteredApplicationsId(this.SearchForm.value.appNumber);
      }
    }
  }
  loadApplicationsByFilter() {
    // this.service.getApplicationById
  }
}

// filter(applicationID) {
//   return (this.newData = this.data.filter(post => post.applicationID === applicationID));
// } else if (this.applicationID === '') {
//   this.ngOnInit();
// }
// searchFilter() {
//   /* this.searchList = this.searchText; */
//   if (this.applicationID !== '') {
//     this.data = this.data.filter(res => {
//       return res.applicationID.toLowerCase().match(this.applicationID.toLowerCase());
//     });
//   } else if (this.applicationID === '') {
//     this.ngOnInit();
//   }
// }
