import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { MsalGuard, MsalService } from '@azure/msal-angular';
import { Observable } from 'rxjs';
import { AuthenticationService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private router: Router,
    private msalGuard: MsalGuard,
    private msalService: MsalService,
    public dialog: MatDialog,
    private authService: AuthenticationService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const targetUrl = state.url;

    /**
     * MSAL Authentication
     */

    const userInfo = this.authService.getUserInfo();

    if (userInfo) {
      return true;
    } else {
      return this.msalGuard.canActivate(next, state);
    }
    
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
      if(this.authService.isAuthenticated()){
        return true;
      } else{
        return false;
      }
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    if(this.authService.isAuthenticated()){
      return true;
    } else{
      return false;
    }
  }

}