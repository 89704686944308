import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../shared/auth/auth.service';
import { DialogService } from '../../services/dialog.service';

import { IwaFeeService } from '../../services/iwafee.service';
@Component({
  selector: 'app-fee',
  templateUrl: './fee.component.html',
  styleUrls: ['./fee.component.scss']
})
export class FeeComponent implements OnInit {
  iwaFeeData: any = {
    ApplFee: "0.0",
    UpdatedBy: "",
    UpdatedDate: ""
  }
  IwaFeeForm: FormGroup = this._formBuilder.group({
    iwaApplFee: "",
    UpdatedBy: "",
    UpdatedDate: ""
  });
  currentUser: string;

  constructor(private _formBuilder: FormBuilder,
    private activeRoute: ActivatedRoute,
    private service: IwaFeeService,
    private router: Router,
    private dialogService: DialogService,
    private authService: AuthenticationService) {
    this.currentUser = authService.getUser().name;
  }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(params => {
      this.service.getIwaFee().subscribe(iwaFeeDetails => {
        this.iwaFeeData = iwaFeeDetails;
        // console.log('from database', iwaFeeDetails);
        this.IwaFeeForm.patchValue({
          iwaApplFee: [this.iwaFeeData.ApplFee, Validators.required],
          UpdatedBy: this.currentUser
        });
      });
    });
  }

  goHome() {
    // this.subscriber$.unsubscribe();
    // this.location.go('/home');
    this.router.navigate(['/home']);
  }

  updateFee(formValue) {
    // console.log('updateFee()');
    // console.log(formValue.iwaApplFee);
    if(formValue.iwaApplFee < 0){
      const res = this.dialogService.ErrorDialog('Entry Error', 'IWA Application Fee should be a positive value.', 'Close', 'Okay');
    }
    else if(isNaN(formValue.iwaApplFee)){
      const res = this.dialogService.ErrorDialog('Entry Error', 'IWA Application Fee must be a number.', 'Close', 'Okay');
    }
    else{
      const confResult = this.dialogService.openDialog(
        "confirmChanges",
        "Confirm Action",
        "Would you like to publish these changes?",
        'Cancel',
        'Submit'
      );
      confResult.afterClosed().subscribe(dialogRes=>{
        if (dialogRes){
          this.service.updateIwaFee(formValue)
          .subscribe(updateResult => {
          // console.log('updateFee() result');
          // console.log(JSON.stringify(updateResult));
          if (updateResult && JSON.stringify(updateResult) === '{"Updated":1}') {
            //this.goHome();
        }
      }, err => {
        console.log(err);
      });

        }
      })
      
    }

  }
}
