import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService, BroadcastService } from "@azure/msal-angular";
import { AuthenticationService } from '../app/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'iap-web';
  displayedName: string;
  loadingText: string;
  isInvalidLoginAttempt = false;
  width: number = window.innerWidth;
  height: number = window.innerHeight;

  constructor(
    private router: Router,
    public msalService: MsalService,
    private authService: AuthenticationService
  ) {
    console.log('screen width is:' + this.width);
    console.log('screen height is:' + this.height);
  }

  onWindowResize(event) {
    this.width = event.target.innerWidth;
    this.height = event.target.innerHeight;

    //console.log('onWindowResize:screen width is:' + this.width);
    //console.log('onWindowResize:screen height is:' + this.height);
  }
  isLoginCompleted() {
    return !this.msalService.loginInProgress();
  }

  ngOnInit(): void {
    this.loadingText = 'Loading Application Information... Please Wait';

    if (!this.msalService.loginInProgress()) {
      const userInfo = this.authService.getUserInfo();

      if (userInfo) {
        this.displayedName = userInfo.name;
      }
    }

  }

  ngOnDestroy(): void { }
}
