import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { IAuthUser } from 'app/auth/auth.model';
import { environment, MSAL_CONFIGURATION } from 'environments/environment';
import { Observable } from 'rxjs';
import { IUser, IMsalStoredToken } from './auth.model';
import { UserAgentApplication, Configuration } from 'msal';
import { AppInsightsService } from "app/app-insights.service";

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {

  private authUser: IUser;
  userAgent: UserAgentApplication;

  constructor(
    private httpClient: HttpClient,
    private msalService: MsalService,
    private appInsights: AppInsightsService
  ) {
    this.userAgent = new UserAgentApplication(MSAL_CONFIGURATION);
  }

  acquireToken = () => {
    console.log("acquiring token")
    var newRequest = {
      scopes: [environment.clientId]
    }
    
    return this.userAgent.acquireTokenSilent(newRequest);
  };

  verifyAccess = (): Observable<IAuthUser> => {
    return this.httpClient.get<IAuthUser>(
      `${environment.apiUrl}/authentication/verifyAccess`
    );
  };

  setUser = (authUser: IUser) => {
    this.authUser = authUser;
  };

  getUser = (): IUser => {
    if(this.authUser){
      return this.authUser;
    }
    else{
      return this.getUserInfo()
    }
    
  };


  isAuthenticated = async (): Promise<boolean> => {
    let userInfo = this.getUserInfo() as IUser;

    if (userInfo) {
      if (environment.ssoEnabled) {
        if (userInfo.emails && userInfo.rolesOnly) {
          this.appInsights.instance.setAuthenticatedUserContext(
            userInfo.emails[0],
            userInfo.rolesOnly[0]
          );
        }
      } else {
        this.appInsights.instance.clearAuthenticatedUserContext();
      }
    }

    return !!this.getUserInfo();
  };

  hasRole(name: string) {
    let retValue: boolean = false;
    let user = this.getUser();

    if (user) {
      if (user.rolesOnly) {
        user.rolesOnly.forEach(role => {
          if (role === name) {
            retValue = true;
          }
        });
      }
    }

    return retValue;
  }

  hasPermission(name: string) {
    let retValue: boolean = false;
    let user = this.getUser();

    if (user) {
      if (user.permissionsOnly) {
        user.permissionsOnly.forEach(permission => {
          if (permission === name) {
            retValue = true;
          }
        });
      }
    }

    return retValue;
  }

  isAdmin() {

    // if (environment.test_roles && this.hasRole("Intl_User")) {
    //   return true;
    // }
    if(this.hasRole('Intl_Admin') || this.hasRole('IWA_Admin') || this.hasRole('IWA_Admin_User')){
      return true;
    }

  }

  isPageReadOnly(pageName) {
    let isPageReadOnly: boolean = false;

    if (environment.ssoEnabled) {
      switch (pageName) {
        case "Announcements":
          {
            if (this.hasRole("Intl_User")) {
              isPageReadOnly = true;
            }
            break;
          }
        case "Newsfeed":
          {
            if (this.hasRole("IA_User")) {
              isPageReadOnly = true;
            }
            break;
          }
        default:
          break;
      }
    }

    // console.log("page: " + pageName + " readOnly property is: " + isPageReadOnly);
    return isPageReadOnly;
  }

  getRolesTestCase(testCase: number) {
    let roles: string = "";
    switch (testCase) {
      case 1:
        //Intl_Admin - ReadWrite access
        roles = environment.roles_case_1;
        break;
      case 2:
        //Intl_User - ReadOnly access
        roles = environment.roles_case_2;
        break;
      case 3:
        //IA_User - Access denied
        roles = environment.roles_case_3;
        break;
    }

    return roles;
  }

  getUserInfo = (): IUser => {

    const userData = this.userAgent.getAccount();

    if (userData) {
      let user = userData.idToken as IUser;

      if (environment.ssoEnabled) {
        if (environment.test_roles) {
          // test roles test cases overriding roles on the client
          user.roles = this.getRolesTestCase(1);
        }

        let rolesOnly: any[] = [];
        let permissionsOnly: any[] = [];

        if (user.roles.length > 0) {
          user.roles.split(", ").forEach(role => {
            let roleSplitArray = role.split("-");

            if (roleSplitArray[0]) {
              let roleOnly = roleSplitArray[0].toString();

              if (!rolesOnly.includes(roleOnly)) {
                rolesOnly.push(roleOnly);
              }

              user.rolesOnly = rolesOnly;
            }

            if (roleSplitArray[1]) {
              let permissionOnly = roleSplitArray[1].toString();

              if (!permissionsOnly.includes(permissionOnly)) {
                permissionsOnly.push(permissionOnly);
              }
            }

            user.permissionsOnly = permissionsOnly;
          });
        }

        //log user's roles
        // console.log("user roles are:" + user.rolesOnly);

        //log user's permission
        // console.log("user permission are:" + user.permissionsOnly);
      }

      this.setUser(user);
      return user;
    }

    return null;
  };

  getCacheAccessToken = (): IMsalStoredToken => {
    let scopes = environment.scopes;

    // console.log('this.msalService.getUser().userIdentifier is: ' + this.msalService.getUser().userIdentifier);

    const key = {
      authority: this.msalService.authority,
      clientId: environment.clientId,
      scopes: scopes[0],
      userIdentifier: this.msalService.getUser().userIdentifier
    };

    const storageValue = localStorage.getItem(JSON.stringify(key));

    //if (storageValue) {
    //console.log("token in session storage is:" + storageValue);
    //}

    return storageValue ? JSON.parse(storageValue) : null;
  };

  logout() {
    this.userAgent.logout();
  }

}
