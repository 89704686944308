import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { AuthenticationService } from '../../auth/auth.service';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.css']
})
export class MainNavComponent implements OnInit, OnDestroy {
  isHandset$: Observable<boolean>;
  destroyed$: any;
  navLinks: any[];
  activeLinkIndex: number;
  isExpanded: boolean;
  name: string;
  activeLinkUrl: string;
  contentLinks: any;
  iwaAdminLinks: any;
  commissionsLinks: any;
  autoEvalLinks: any;
  agencyLinks: any;
  userAdminLinks: any;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public _router: Router,
    private authService: AuthenticationService,
    private msalService: MsalService
  ) {
    this.isExpanded = true;
    this.activeLinkIndex = -1;

    this.destroyed$ = new Subject();

    this.loadFooterRightIcons();

    this.isHandset$ = this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.Tablet]).pipe(
      map(result => {

        //console.log(result.breakpoints);

        if
          (
          result.matches && window.innerWidth < 1280
        ) {
          // console.log('screen width is:' + window.innerWidth);
          //console.log('handheld or tablet detected');
          return true;
        }

        //console.log('desktop detected');
        return false;
        /*
        console.log('isExpanded is: ' + this.isExpanded);
        console.log(result.breakpoints);
        console.log("result matches is: " + result.matches);
        return result.matches;
        */
      }),
      takeUntil(this.destroyed$)
    );

    this.navLinks = [];

    this.contentLinks = {
      label: 'Content',
      link: './content',
      iconName: 'rss_feed',
      isPanelHiddenParent: true,
      isPanelParent: true,
      index: 4,
      children: [
        {
          label: 'Announcements',
          link: './content/announcement',
          iconName: 'home',
          index: 0
        },
        {
          label: 'Newsfeed',
          link: './content/newsfeed',
          iconName: 'home',
          index: 0
        },
        {
          label: 'Resources',
          link: './content/resources',
          iconName: 'home',
          index: 0
        }
      ]
    };

    this.iwaAdminLinks = {
      label: 'IWA Admin',
      link: './iwa',
      iconName: 'admin_panel_settings',
      isPanelHiddenParent: true,
      isPanelParent: true,
      index: 2,
      children: [
        {
          label: 'Applications',
          link: './iwa/applications',
          iconName: 'home',
          index: 0
        },
        {
          label: 'Academic Programs',
          link: './iwa/academic-programs',
          iconName: 'home',
          index: 1
        },
        {
          label: 'Batch Control',
          link: './iwa/batch-control',
          iconName: 'home',
          index: 3
        },
        {
          label: 'IWA Fee',
          link: './iwa/fee',
          iconName: 'home',
          index: 4
        },
        {
          label: 'Promo Code',
          link: './iwa/promo-code',
          iconName: 'home',
          index: 5
        }
        ,
        {
          label: 'Country Restriction',
          link: './iwa/country-restriction',
          iconName: 'home',
          index: 3
        }
      ]
    };

    this.autoEvalLinks = {
      label: 'Auto Eval',
      link: './autoeval',
      iconName: 'business',
      isPanelHiddenParent: true,
      isPanelParent: true,
      index: 5,
      children: [
        {
          label: 'AutoEval List',
          link: './autoeval/autoeval-list',
          iconName: 'home',
          index: 0
        },
        {
          label: 'AutoEval Edit',
          link: './autoeval/autoeval-edit',
          iconName: 'home',
          index: 1
        },
        {
          label: 'AutoEval Report',
          link: './autoeval/autoeval-report',
          iconName: 'home',
          index: 1
        }
      ]
    }

    this.commissionsLinks = {
      label: 'Commissions',
      link: './commissions',
      iconName: 'monetization_on',
      isPanelHiddenParent: true,
      isPanelParent: true,
      index: 4,
      children: [
        {
          label: 'Load Agencies',
          link: './commissions/batch',
          index: 1
        },
        {
          label: 'Search Agencies',
          link: './commissions/searchAgencies',
          index: 2
        },
        {
          label: 'Configuration',
          link: './commissions/config',
          index: 3
        },
        {
          label: 'Lock Agencies',
          link: './commissions/readOnly',
          index: 4
        }
      ]
    }

    this.agencyLinks = {
      label: 'Agency',
      link: './agency',
      iconName: 'business',
      isPanelHiddenParent: true,
      isPanelParent: true,
      index: 5,
      children: [
        {
          label: 'Agencies',
          link: './agency/agencies',
          iconName: 'home',
          index: 0
        },
        {
          label: 'Agencies Summary',
          link: './agency/agency-summary',
          iconName: 'home',
          index: 1
        }
      ]
    }

    this.userAdminLinks = {
      label: 'User',
      link: './users',
      iconName: 'business',
      isPanelHiddenParent: true,
      isPanelParent: true,
      index: 5,
      children: [
        {
          label: 'Users',
          link: './users/user-list',
          iconName: 'home',
          index: 0
        }
      ]
    }
    
  }

  isLoginCompleted() {
    return !this.msalService.loginInProgress();
  }

  public ngOnInit() {
    this.router.events.subscribe(res => {
      this.activeLinkIndex = this.navLinks.indexOf(this.navLinks.find(tab => tab.link === '.' + this.router.url));
    });    

    if (!this.msalService.loginInProgress()) {
      const userInfo = this.authService.getUser();

      if (userInfo) {
        this.name = userInfo.name;
      }

      this.navLinks.push({
        label: 'Home',
        link: './home',
        iconName: 'home',
        isPanelHiddenParent: false,
        isPanelParent: false,
        index: 0
      });

      if(this.authService.hasRole('IWA_Admin')){       
        this.navLinks.push(this.iwaAdminLinks);               
      }
      else if(this.authService.hasRole('IWA_Admin_User'))
      {
        // remove the fee and batch control menu items for IWA_Admin_User role 
        this.iwaAdminLinks.children.forEach((element, index) => {
          if (element.label === "Batch Control") {
            this.iwaAdminLinks.children.splice(index, 1);
          }
        });  
        this.iwaAdminLinks.children.forEach((element, index) => {
          if (element.label === "IWA Fee") {
            this.iwaAdminLinks.children.splice(index, 1);
          }
        });    
        this.navLinks.push(this.iwaAdminLinks)
      }

      if (this.authService.hasRole('ITSAccess')){
        this.navLinks.push(this.autoEvalLinks);
        this.navLinks.push(this.userAdminLinks);
      }
      
      if (this.authService.hasRole('Intl_Auto_Eval')){
        this.navLinks.push(this.autoEvalLinks);
      }
      
      if (this.authService.hasRole('IWA_Commissions_User'))
      {
        this.navLinks.push(this.commissionsLinks);
      }

      if(this.authService.hasRole('Intl_Admin')){
        this.navLinks.push(this.contentLinks);
        this.navLinks.push(this.agencyLinks);
      }

      this.navLinks.push({
        label: 'Logout',
        link: './logout',
        iconName: 'exit_to_app',
        isPanelHiddenParent: false,
        isPanelParent: false,
        index: 7
      })
    }
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((res: NavigationEnd) => {
      this.activeLinkUrl = './' + res.url.split('/')[1];
      //console.log('route', this.activeLinkUrl);
    });
  }

  public onHomeClick = () => {
    this.router.navigateByUrl('/');
  };

  public openLink(url: string) {
    window.open(url, '_blank');
  }

  public ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.unsubscribe();
  }

  private showPanelItem(link: any, isExpanded: boolean) {
    const retValue = link.isPanelParent === true && isExpanded === true;

    return retValue;
  }

  private loadFooterRightIcons() {
    this.matIconRegistry.addSvgIcon('facebook', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/facebook-f-brands.svg'));
    this.matIconRegistry.addSvgIcon('instagram', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/instagram-brands.svg'));
    this.matIconRegistry.addSvgIcon('linkedin', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/linkedin-brands.svg'));
    this.matIconRegistry.addSvgIcon('twitter', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/twitter-brands.svg'));
    this.matIconRegistry.addSvgIcon('youtube', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/youtube-brands.svg'));
  }
}
