import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-country-restriction',
  templateUrl: './country-restriction.component.html',
  styleUrls: ['../../../content/shared/css/commonList.css', './country-restriction.component.scss']
})
export class CountryRestrictionComponent implements OnInit {
  loadProgram: boolean;
  term = '';
  constructor() {}

  ngOnInit() {
    this.term = this.getDefaultTerm();
    this.loadProgram = true;
    
  }

  loadPrograms(term) {
    this.loadProgram = true;
    this.term = term;
  }

  private getDefaultTerm(): string{

    const date: Date = new Date();
    let normalAppliedMonths = [0, 4, 8];
    const nextTermMonth = normalAppliedMonths.find(month => month >= date.getMonth());
    let year = date.getFullYear().toString();
    let month = nextTermMonth ? nextTermMonth : normalAppliedMonths[0];
    let strm = '';

    let y = year.substring(0, 1);

    if (year.substring(1, 2) === '0') {
        y = y + year.substring(2, 4);
    }
    else {
        y = y + year.substring(1, 4);
    }

    if (month === 0) {
        strm = y + '1';
    }
    else if (month === 4) {
        strm = y + 4;
    }
    else if (month === 8) {
        strm = y + '7';
    }
    return strm;
  }
}
