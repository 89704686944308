// import { BackToTopComponent } from './../shared/components/back-to-top/back-to-top.component';
import { NgModule, Component } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IwaRoutingModule } from './iwa-routing.module';
import { SharedModule } from '../shared/shared.module';
import { IwaComponent } from './iwa.component';
import { AcademicProgramsComponent } from './components/academic-programs/academic-programs.component';

import { CountryRestrictionComponent } from './components/country-restriction/country-restriction.component';
import { CountryRestrictionNewComponent } from './components/country-restriction/country-restriction-new/country-restriction-new.component';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import {BackToTopComponent}
import { MaterialModule } from '../shared/material.module';
// import { FileUploadModule } from 'ng2-file-upload';
// import { QuillModule } from 'ngx-quill';
import { AcademicProgramsListComponent } from './components/academic-programs/academic-programs-list/academic-programs-list.component';
import { ApplicationsListModule } from './components/applications/applications-list/applications-list.module';
import { PromoCodeModule } from './components/promo-code/promo-code.module';
import { IwaFeeModule } from './components/fee/iwa-fee.module';
import { BatchControlModule } from './components/batch-control/batch-control.module';
import { DialogComponent } from '../iwa/shared/dialog/dialog.component';
import { CountryRestrictionListComponent } from './components/country-restriction/country-restriction-list/country-restriction-list.component';
// import {
//   PdfViewerComponent
// } from '@syncfusion/ej2-angular-pdfviewer';
// import { PdfViewerModule } from 'ng2-pdf-viewer';
// import { PdfJsViewerComponent, PdfJsViewerModule } from 'ng2-pdfjs-viewer';
// import { SimplePdfViewerModule } from 'simple-pdf-viewer';
@NgModule({
  declarations: [
    //AdminNewsfeedComponent,
    //AdminAnnouncementComponent,
    //AdminResourceComponent,
    IwaComponent,
    AcademicProgramsComponent,
    CountryRestrictionComponent,
    CountryRestrictionNewComponent,
    AcademicProgramsListComponent,
    DialogComponent,
    CountryRestrictionListComponent
    //, PdfJsViewerComponent, PdfJsViewerModule
    // PdfViewerComponent
    // BackToTopComponent
    // DialogComponent
  ],
  imports: [
    CommonModule,
    // BackToTopComponent,
    FormsModule,
    ReactiveFormsModule,
    IwaRoutingModule,
    HttpClientModule,
    SharedModule,
    MaterialModule,
    ApplicationsListModule,
    PromoCodeModule,
    IwaFeeModule,
    BatchControlModule
  ],

  exports: [
    AcademicProgramsComponent,
    CountryRestrictionComponent,
    CountryRestrictionNewComponent,
  ],
  entryComponents: [DialogComponent]
})
export class IwaModule { }
