import { commonEnv } from './environment.common';
import { Configuration } from "msal";

const instrumentationKey = commonEnv.ssoEnabled
  ? ""
  : "";
const clientId = commonEnv.ssoEnabled
  ? "f36d58c0-2645-4b3f-8285-331bbdf0db87"
  : "f36d58c0-2645-4b3f-8285-331bbdf0db87";
const tenant = commonEnv.ssoEnabled
  ? "iaptstb2c.onmicrosoft.com"
  : "iaptstb2c.onmicrosoft.com";
const scopes =  commonEnv.ssoEnabled? [clientId]: ['https://IAPTSTB2C.onmicrosoft.com/admin/read'];
//const azureInstance = commonEnv.azureInstance;
const azureInstance = commonEnv.ssoEnabled?
 `https://IAPTSTB2C.b2clogin.com/tfp`
: `https://IAPTSTB2C.b2clogin.com/tfp`;
const appUrl = 'https://intl-admin-tst.senecapolytechnic.ca';

export class BuildEnvironment {
  constructor() {}

  public static GetEnvironmentUrl(): string {
    return environment.apiUrl;
  }
}

export const MSAL_CONFIGURATION: Configuration = {
  auth: {
    clientId: clientId,
    authority: `${azureInstance}/${tenant}/${commonEnv.agentSignInPolicy}`,
    validateAuthority: false,
    redirectUri: `${appUrl}`,
    navigateToLoginRequestUrl: false,
    postLogoutRedirectUri: `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${appUrl}/logout`,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true
  }
};

export const environment = {
  //SSO settings - starts
    ssoEnabled: commonEnv.ssoEnabled? commonEnv.ssoEnabled: false,
    test_roles: commonEnv.ssoEnabled? false:false,
    roles_case_1: commonEnv.ssoEnabled? 'Intl_Admin': '',
    roles_case_2: commonEnv.ssoEnabled? 'Intl_User': '',
    roles_case_3: commonEnv.ssoEnabled? 'IA_User': '',
  //SSO settings - ends
  production: true,
  appUrl: appUrl,
  scopes: scopes,
  clientId: clientId,
  apiUrl: 'https://seneapi-apims-tst.azure-api.net/adminportal/public/controllers/api',
  apiSubscriptionKeyName: 'ADMNPRTL-Apim-Subscription-Key',
  apiSubscriptionKey: 'c2f838a164a54842a95c42a58e41476e',
  apiSubscriptionEnabled: true,
  agentSignInPolicy: commonEnv.agentSignInPolicy,
  applicantSignInPolicy: commonEnv.applicantSignInPolicy,
  azureInstance: azureInstance,
  //SSO settings
   appInsights: {
    instrumentationKey: instrumentationKey
  },
  tenant: tenant,
  msalConfig: {
    clientID: clientId,
    authority: `${azureInstance}/${tenant}/${commonEnv.agentSignInPolicy}`,
    validateAuthority: false,
    consentScopes: scopes,
    redirectUri: `${appUrl}`,
    cacheLocation: "localStorage",
    navigateToLoginRequestUrl: false,
    postLogoutRedirectUri: `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${appUrl}/logout`,
    storeAuthStateInCookie: true
  }
};
