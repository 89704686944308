
<div class="seneca-list-container">
  <mat-card class="main-container">
  <mat-card-title>
    <h1 class="card-title">Applications</h1>
  </mat-card-title>
  <div id="applications">
    <form #applications="ngForm" id="applications-search" [formGroup]="SearchForm">

      <mat-form-field id="appNumber" appearance="outline">
        <mat-label>Application Number</mat-label>
        <input matInput #search maxlength="11" placeholder="Application Number" formControlName="appNumber"/>
      </mat-form-field>

      <div id="or">
        <span> <b>- OR - </b></span>
      </div>

      <mat-form-field id="status" appearance="outline">
        <mat-label>Status</mat-label>
        <mat-select matInput placeholder="Status" required  formControlName="appStatus">
          <ng-container>
            <mat-option>--</mat-option>
            <mat-option *ngFor="let st of ApplStatusList" [value]="st"> {{st}}</mat-option>
          </ng-container>
        </mat-select>

      </mat-form-field>
      <div id="or">
        <span> <b>- AND/OR - </b></span>
      </div>
      <mat-form-field id="term" appearance="outline">
        <mat-label>Term</mat-label>
        <input matInput placeholder="Term" required  formControlName="appTerm"/>

      </mat-form-field>
      <!--
      <mat-form-field id="submitStatus" appearance="outline">
        <mat-label>Error on submit</mat-label>
        <mat-select matInput placeholder="Error on Submit" required>
          <ng-container>
            <mat-option>--</mat-option>
            <mat-option *ngFor="let st of submitStatus" [value]="st.id"> {{st.value}}</mat-option>
          </ng-container>
        </mat-select>

      </mat-form-field>
    -->

      <div class="actions-box">
        <mat-card-actions>

          <button type="button" id="search" mat-flat-button class="seneca-button" color="primary"
            (click)="searchApplication(null)" onclick="this.blur()">
            <span>Search</span>
          </button>
        </mat-card-actions>
      </div>
    </form>
  </div>
</mat-card>
<br/>

<mat-card class="result-container">
  <mat-card-content id="result-content">
    <h2 class="result-title"> Search Results </h2>
    <div class="search-result">

      <table mat-table [dataSource]="dataSource" matSort CELLSPACING=0 role="cell">
        <ng-container matColumnDef="ApplicationId">
          <th mat-header-cell *matHeaderCellDef hidden="hidden"></th>
          <td mat-cell *matCellDef="let element" ><a [href]='"/iwa/applications/"+element.Application_Id'
            (click)="loadRequestedApplication(element.Application_Id);$event.preventDefault()">{{element.Application_Id}}</a></td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>

      </table>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 20, 50, 100]" showFirstLastButtons> </mat-paginator>
  </mat-card-content>
  <app-view-application id="view-app" *ngIf="loadSearchResult" [applicationNum]="applicationSearch" [status]="ApplStatusList" (loadRequestedApplication)="loadRequestedApplication($event)"></app-view-application>

</mat-card>


  
</div>
