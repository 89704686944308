import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../../shared/material.module';
import { SharedModule } from '../../../shared/shared.module';
import { IwaRoutingModule } from '../../iwa-routing.module';
import { PromoCodeComponent } from './promo-code.component';
import { PromoCodeDetailsComponent } from './promo-code-details/promo-code-details.component';


@NgModule({
  declarations: [PromoCodeComponent,
    PromoCodeDetailsComponent,
  ],
  imports: [
    CommonModule, MaterialModule, FormsModule,
    ReactiveFormsModule, HttpClientModule, SharedModule, IwaRoutingModule
  ],
  exports: [PromoCodeComponent,
    PromoCodeDetailsComponent, MaterialModule
  ]
})
export class PromoCodeModule { }
