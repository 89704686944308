<div class="seneca-details-container">
  <!-- *ngIf="data"  -->
  <mat-card id="main-mat-card">
    <mat-card-title>
      <h2 class="card-title">Country Restriction</h2>
    </mat-card-title>

    <div class="mat-content">
      <!-- (ngSubmit)="submitCountryRestriction(countryRestrictionForm.value)" -->
      <form #countryRestrictionForm="ngForm" (ngSubmit)="abc()" class="form-container">

        <div id="rowOne" class="top-actions">
          <mat-form-field id="country" appearance="outline">
            <mat-label>Country</mat-label>
            <mat-select [(ngModel)]="selectedCountry" name="Country">
              <mat-option *ngFor="let country of CountriesList" [value]="country.Country">
                {{country.Descr}}
              </mat-option>
            </mat-select>
            <mat-error><em class="error-hint"> Required</em> </mat-error>
          </mat-form-field>
          &nbsp;&nbsp;&nbsp;
          <mat-form-field id="term" appearance="outline">
            <mat-label>Term</mat-label>
            <!-- [(ngModel)]="data.term"  -->
            <input matInput #term placeholder="Term *" name="Term" required />
            <mat-error><em class="error-hint"> Required</em> </mat-error>
            <mat-hint align="start">Four digits </mat-hint>
          </mat-form-field>
          &nbsp;&nbsp;&nbsp;
          <button mat-flat-button type="button" class="seneca-button" color="primary"
            (click)="loadPrograms(term.value)">
            Load Programs
          </button>

        </div>

        <div class="seneca-table-container seneca-elevation">
          <table mat-table [dataSource]="dataSource" matSort>
            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle() : null"
                  [checked]="selectionHasValue() && isAllSelected()"
                  [indeterminate]="selectionHasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation();logDebug(selection)"
                  (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"
                  [aria-label]="checkboxLabel(row)">
                </mat-checkbox>
              </td>
            </ng-container>

            <ng-container matColumnDef="PROG_DESCR">
              <th class="priority-1" mat-header-cell *matHeaderCellDef mat-sort-header>Program Name</th>
              <td class="priority-1" mat-cell *matCellDef="let element">{{ element.PROG_DESCR }}</td>
            </ng-container>

            <ng-container matColumnDef="ACAD_PROG">
              <th class="priority-2" mat-header-cell *matHeaderCellDef mat-sort-header>Code</th>
              <td class="priority-2" mat-cell *matCellDef="let element">{{ element.ACAD_PROG }}</td>
            </ng-container>

            <ng-container matColumnDef="CAMPUS_DESC">
              <th class="priority-3" mat-header-cell *matHeaderCellDef mat-sort-header>Campus</th>
              <td class="priority-3" mat-cell *matCellDef="let element">{{ element.CAMPUS_DESC }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
          <mat-paginator [pageSizeOptions]="[5, 10, 20, 50, 100]" showFirstLastButtons> </mat-paginator>
        </div>


        <div class="actions-box">
          <mat-card-actions>
            <div class="left-buttons">
            </div>
            <div class="right-buttons">
              <button mat-button type="button" class="seneca-button" color="primary" (click)="goBack()">
                <span>Back</span>
              </button>

              <!-- [disabled]="id < 1 || announcementForm.invalid" (click)="onPublish()" -->
              <button type="button" mat-flat-button class="seneca-button" color="primary" (click)="addNewCountryRestrictions()">
                <span>Save</span>
              </button>
            </div>
          </mat-card-actions>
        </div>
      </form>
      <mat-card-content class="mat-elevation-z8"> </mat-card-content>
    </div>
  </mat-card>
</div>