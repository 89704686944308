import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { IAuthUser, IMSALInfo, IUser } from './auth.model';
import { MsalService } from '@azure/msal-angular';


@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {

    private authUser: IAuthUser;

  constructor(
    private httpClient: HttpClient,
    private msalService: MsalService,
    ) {}

  verifyAccess = (): Observable<IAuthUser> => {
    return this.httpClient.get<IAuthUser>(
      `${environment.apiUrl}/authentication/verifyAccess`
    );
  };

  setUser = (authUser: IAuthUser) =>{
      this.authUser = authUser;
  };

  getUser = (): IAuthUser => {
      return this.authUser;
  };


  isAuthenticated = async (): Promise<boolean> => {
    return !!this.getUserInfo();
  };

  getUserInfo = (): IUser => {
    const userData = this.msalService.getUser();
    return userData ? (userData.idToken as IUser) : null;
  };

}
