import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BatchcontrolService } from '../../services/batchcontrol.service';
import { IBatchControl, BatchControl } from '../models/batch-control';
import { DialogService } from '../../services/dialog.service';

export interface Option {
  value: string;
}
@Component({
  selector: 'app-batch',
  templateUrl: './batch.component.html',
  // '../../../content/shared/css/commonAddNew.css'
  styleUrls: ['../../../content/shared/css/commonList.css', '../../../content/shared/css/commonAddNew.css', './batch.component.scss']
})
export class BatchComponent implements OnInit {
  //   <!--
  //   <input matInput placeholder="Batch Running" formControlName="BatchRunning_{{ conf.Idx }}" value="{{ conf.BatchRunning }}"/>
  //   <mat-error> <em
  //       *ngIf="this?.touched && this?.invalid">
  //       Required</em> </mat-error>
  // -->

  runningOptions: Option[] = [{ value: 'Yes' }, { value: 'No' }];
  batchControlData: IBatchControl[] = [
    {
      Idx: 1,
      BatchName: 'Applications',
      BatchRunning: 'No',
      BatchStart: '7:00',
      BatchEnd: '20:00',
      BatchFrequency: '3600000'
    },
    {
      Idx: 2,
      BatchName: 'Documents',
      BatchRunning: 'No',
      BatchStart: '8:00',
      BatchEnd: '21:00',
      BatchFrequency: '3600000'
    }
  ];


  BatchControlConfigForm: FormGroup = this._formBuilder.group({
    BatchName_1: '',
    BatchRunning_1: '',
    BatchStart_1: '',
    BatchEnd_1: '',
    BatchFrequency_1: '',
    BatchName_2: '',
    BatchRunning_2: '',
    BatchStart_2: '',
    BatchEnd_2: '',
    BatchFrequency_2: ''
  });

  constructor(private _formBuilder: FormBuilder,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private service: BatchcontrolService) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(params => {
      this.service.getBatchControlConf().subscribe((batchControlDb: BatchControl[]) => {
        this.batchControlData = batchControlDb;
        // console.log('from database', iwaFeeDetails);
        // this.BatchControlConfigForm.patchValue({
        //   iwaApplFee: [this.iwaFeeData.ApplFee, Validators.required]
        // });
      });
    });
  }

  goHome() {
    // this.subscriber$.unsubscribe();
    // this.location.go('/home');
    this.router.navigate(['/home']);
  }

  invokeApplicationBatch(){
    this.service.invokeApplicationBatch().subscribe(res=> {
      this.dialogService.MessageBox("Application Submission Initiated", 'X',5000,'SuccessMessage');
    });
  }

  invokeDocumentsBatch(){
    this.service.invokeDocumentsBatch().subscribe(res=> {
      this.dialogService.MessageBox("Application Submission Initiated", 'X',5000,'SuccessMessage');
    });
  }

  updateBatchConf(formValue) {
    console.log('updateBatchConf()');
    console.log(formValue);
    this.service.updateBatchControlConf(formValue)
      .subscribe(updateResult => {
        // console.log('updateFee() result');
        // console.log(JSON.stringify(updateResult));
        if (updateResult && JSON.stringify(updateResult) === '{"Updated":1}') {
          this.dialogService.MessageBox('SUCCESS - Batch Control Configuration Updated', 'X', 2000, 'SuccessMessage');
          //alert('SUCCESS - Batch Control Configuration Updated');
        }
      }, err => {
        console.log(err);
      });
  }
}
