import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../shared/auth/auth.service';
import { MsalService, BroadcastService } from "@azure/msal-angular";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  name: string;

  constructor(
    private msalService: MsalService,
    private authService: AuthenticationService
  ) { }

  isLoginCompleted() {
    return !this.msalService.loginInProgress();
  }

  ngOnInit() {

    if (!this.msalService.loginInProgress()) {
      const userInfo = this.authService.getUser();

      if (userInfo) {
        this.name = userInfo.name;
      }
    }
  }

}
