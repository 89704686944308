import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../../shared/material.module';
import { SharedModule } from '../../../shared/shared.module';
import { FeeComponent } from './fee.component';

@NgModule({
  declarations: [FeeComponent],
  imports: [
    CommonModule, MaterialModule, FormsModule,
    ReactiveFormsModule, HttpClientModule, SharedModule,
  ],
  exports: [FeeComponent, MaterialModule]
})
export class IwaFeeModule { }
