import { HttpHandler, HttpInterceptor, HttpRequest, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationService } from '../auth/auth.service';
import { environment } from 'environments/environment';
import { fromPromise } from 'rxjs/internal/observable/fromPromise';
import { Observable } from 'rxjs/Observable';
import { DialogService } from 'app/content/services/dialog.service'; 

@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorHeaderService implements HttpInterceptor {

  constructor(
    private msalService: MsalService,
    private authService: AuthenticationService,
    private dialogService: DialogService
  ) {}

  private async handleAccess(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Promise<HttpEvent<any>> {

    const headerSettings: { [name: string]: string | string[] } = {};
    let token: string;

    try{
      
      if (!environment.ssoEnabled)
      {
        token = localStorage.getItem('msal.idtoken');
      }
      else
      {
        if (this.authService.getCacheAccessToken()) {
          let numberTime = Number(this.authService.getCacheAccessToken().expiresIn);
          //console.log("token expires in: " + numberTime.toString());
    
          let currentNumberTime = Math.floor(Date.now() / 1000);
          //console.log(" currentNumberTime in: " + currentNumberTime.toString());
        } else {
          console.log("access token is null");
        }
    
        if (
          this.authService.getCacheAccessToken() &&
          Number(this.authService.getCacheAccessToken().expiresIn) >
            Math.floor(Date.now() / 1000)
        ) 
        {
          token = this.authService.getCacheAccessToken().accessToken;
        } 
        else 
        {
          // Rubinder - Token renewal functionality is not working with the current version of the msal library
          // so disabling that for now and forcing the user to logout after showing a message
          // console.log("acquiring token call");
          // let authResponse = await this.authService.acquireToken();
          // token = authResponse.accessToken;
          const res = this.dialogService.openDialog(0,'Session Timeout', "Your current session has expired", 'logout', 'Logout');
          res.afterClosed().subscribe(dialogResult => {});
        }
      }

      // assign token to Authorization header
      if (token) {
        headerSettings['Authorization'] = 'Bearer ' + token;
      }
    }
    catch(ex){
      // just to prevent an exception, when there is no token, as we do have some open API calls
    }    

    headerSettings[environment.apiSubscriptionKeyName] = environment.apiSubscriptionKey;

    return next
    .handle(
      request.clone({
        setHeaders: headerSettings
      })
    )
    .toPromise();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) : Observable<HttpEvent<any>> {
    return fromPromise(
      this.handleAccess(req,next)
    );
  }
}
