import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, RouterStateSnapshot, UrlSegment, Router } from '@angular/router';
import { MsalGuard, MsalService } from '@azure/msal-angular';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

import { AuthenticationService } from './auth.service';
import { DialogService } from 'app/content/services/dialog.service';

@Injectable({
  providedIn: "root"
})
export class AuthGuard implements CanActivate, CanLoad, OnDestroy {
  constructor(
    private authService: AuthenticationService,
    private msalGuard: MsalGuard,
    private msalService: MsalService,
    private router: Router,
    private dialogService: DialogService,
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean>
  {
    const targetUrl = state.url;

    let isAuthenticated = await this.authService.isAuthenticated(); 

    if (!isAuthenticated) {

      
      this.msalService.authority = environment.msalConfig.authority;
      return this.msalGuard.canActivate(route, state);
    }

    if (isAuthenticated) 
    {
      if (this.authService.isAdmin())
      {
        if(targetUrl.match(`/home`)){
          return true;
        }
        
        if ((targetUrl.match(`/content`) || targetUrl.match('/agency') || targetUrl.match('/commissions')) && this.authService.hasRole('Intl_Admin')) {
          return true;
        }        
        
        if (targetUrl.match(`/iwa`) && (this.authService.hasRole('IWA_Admin') || this.authService.hasRole('IWA_Admin_User'))){
          return true;
        }
        if (targetUrl.match(`/users`) || targetUrl.match('/autoeval') && (this.authService.hasRole('ITSAccess'))){
          return true;
        }
        if (targetUrl.match('/autoeval') && (this.authService.hasRole('Intl_Auto_Eval'))) {
          return true;
        }
        else{
          const res = this.dialogService.openDialog(0,'Access Denied Error', "You do not have permission to access this menu item", 'OK', 'Cancel');
          res.afterClosed().subscribe(dialogResult => {
            if (dialogResult) {
              return;
            }
          });

          return false;
        }
      }
      else
      {       
        const res = this.dialogService.openDialog(0,'Access Denied Error', "You do not have permission to access this application", 'OK', 'Cancel');
        res.afterClosed().subscribe(dialogResult => {
          if (dialogResult) {
            return;
          }
        });

        return false;
      }
  }
  else
  {      
     return true;
  }
}
    
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): boolean | Observable<boolean> | Promise<boolean> 
  {
    if (this.authService.isAuthenticated()) {
        return true;     
    } 
    else 
    {     
      return false;
    }
  }

  ngOnDestroy(): void {}
}
