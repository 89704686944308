import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApplicationRef, DoBootstrap, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatIconModule, MatIconRegistry, MatInputModule } from '@angular/material';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { QuillModule } from 'ngx-quill';
import { SenecaAngularErrorHandlerService } from '../../private_modules/seneca-angular-error-handler';
import { ServerErrorInterceptor } from '../../private_modules/seneca-angular-server-error-interceptor';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { SharedModule } from './shared/shared.module';
import { HomeComponent } from './home/home.component';
import { IwaModule } from './iwa/iwa.module';

import { RequestsComponent } from './requests/requests.component';
import { RequestsModule } from './requests/requests.module';
import { MsalModule, MsalService, MsalInterceptor } from '@azure/msal-angular';
import { MSAL_CONFIG } from '@azure/msal-angular/dist/msal.service';
import { getMsalConfig } from './auth/auth.functions';
import { HttpInterceptorHeaderService } from './shared/services/httpInterceptorHeaders.service';
import { AuthenticationService } from './auth/auth.service';
import { DialogComponent } from './content/shared/dialog/dialog.component';
import { addCommentDialog } from './content/shared/commentDialog/commentDialog';
import { UserListComponent } from './users/components/user-list/user-list.component';
import { AutoevalComponent } from './autoeval/autoeval.component';
import { AutoevalEditComponent } from './autoeval/components/autoeval-edit/autoeval-edit.component';

@NgModule({
  declarations: [AppComponent, PageNotFoundComponent, HomeComponent, DialogComponent,addCommentDialog],

  imports: [
    MatIconModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatAutocompleteModule,
    QuillModule.forRoot({
      modules: {
        syntax: true,
        toolbar: true,
        readonly: true
      }
    }
    ),

    // ContentModule,
    SharedModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    }),
    LoggerModule.forRoot({
      level: !environment.production ? NgxLoggerLevel.LOG : NgxLoggerLevel.OFF,
      serverLogLevel: NgxLoggerLevel.OFF
    })
    , IwaModule,
    MsalModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true
    },
    //{
      //provide: ErrorHandler,
      //useClass: SenecaAngularErrorHandlerService
    //},
    MsalService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_CONFIG,
      useValue: getMsalConfig(),
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorHeaderService, multi: true },
    AuthenticationService
  ],
  entryComponents: [AppComponent, DialogComponent, addCommentDialog]
})
export class AppModule implements DoBootstrap {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg')); // Or whatever path you placed mdi.svg at
  }

  ngDoBootstrap(ref: ApplicationRef) {
    if (window !== window.parent && !window.opener) {
    } else {
      ref.bootstrap(AppComponent);
    }
  }
}
