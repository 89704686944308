import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadingStrategy, PreloadAllModules } from '@angular/router';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { HomeComponent } from './home/home.component';
import { LogoutComponent } from './shared/components/logout/logout.component';
import { AppComponent } from './app.component';
import { IwaComponent } from './iwa/iwa.component';

import { AuthenticationResolverService } from './auth/auth.resolver';
import { AuthGuard } from './shared/auth/auth.guard';
import { AdminGuard } from './auth/admin.guard';
import { environment } from 'environments/environment';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home', pathMatch: 'full'
  },
  {
    path: 'home',
    canActivate: environment.ssoEnabled ? [AuthGuard] : [AdminGuard],
    canLoad: environment.ssoEnabled ? [AuthGuard] : [AdminGuard],
    component: HomeComponent
  },
  {
    path: 'content',
    canActivate: environment.ssoEnabled ? [AuthGuard] : [AdminGuard],
    canLoad: environment.ssoEnabled ? [AuthGuard] : [AdminGuard],
    loadChildren: './content/content.module#ContentModule'
  },
  {
    path: 'commissions',
    canActivate: environment.ssoEnabled ? [AuthGuard] : [AdminGuard],
    canLoad: environment.ssoEnabled ? [AuthGuard] : [AdminGuard],
    loadChildren: './commissions/commissions.module#CommissionsModule'
  },
  {
    path: 'agency',
    canActivate: environment.ssoEnabled ? [AuthGuard] : [AdminGuard],
    canLoad: environment.ssoEnabled ? [AuthGuard] : [AdminGuard],
    loadChildren: './agency/agency.module#AgencyModule'
  },
  {
    path: 'users',
    canActivate: environment.ssoEnabled ? [AuthGuard] : [AdminGuard],
    canLoad: environment.ssoEnabled ? [AuthGuard] : [AdminGuard],
    loadChildren: './users/users.module#UsersModule'
  },
  {
    path: 'autoeval',
    canActivate: environment.ssoEnabled ? [AuthGuard] : [AdminGuard],
    canLoad: environment.ssoEnabled ? [AuthGuard] : [AdminGuard],
    loadChildren: './autoeval/autoeval.module#AutoevalModule'
  },
  {
    path: 'requests',
    loadChildren: './requests/requests.module#RequestsModule'
  },
  {
    path: 'logout', component: LogoutComponent
  },
  { path: 'iwa', canActivate: [AuthGuard], canLoad: [AuthGuard], loadChildren: './iwa/iwa.module#IwaModule' },
  {
    path: '**', component: PageNotFoundComponent
  },
  {
    path: 'denied', component: PageNotFoundComponent
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
