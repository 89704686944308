import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpClientModule } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { BuildEnvironment } from 'environments/environment';
// import { INewsFeed } from '../models/academicPrograms.model';

@Injectable({
  providedIn: 'root'
})
export class AcademicProgramsService {
  private baseurl = BuildEnvironment.GetEnvironmentUrl();

  constructor(private http: HttpClient) { }

  getAllAcademicProgram(term): Observable<any> {
    return this.http.get(this.baseurl + '/academicprogram/getacademicprograms/' + term);
  }

  //get academic programs from peoplesoft
  getAllAcademicProgramsPS(term): Observable<any> {
    return this.http.get(this.baseurl + '/academicprogram/loadacadprograms/' + term);
  }

  //load bulk status actions
  getAllStatuses(): Observable<any> {
    return this.http.get(this.baseurl + "/academicprogram/getacadprogactions/");
  }

  getAcademicProgramsById(academicProgramsid: string): Observable<any> {
    return this.http.get(this.baseurl + '/' + academicProgramsid);
  }

  saveAcademicPrograms(formdata) {
    return this.http.post(this.baseurl + '/insert', formdata);
  }

  updateAcademicPrograms(formdata, academicProgramsid) {
    // try {
    return this.http.post(this.baseurl + '/update/' + academicProgramsid, formdata);
    // } catch (err) {
    //   console.log('-------Error occurred update posting----' + err);
    // }
  }

  updateAcademicBatch(uri, updatedOptions) {
    // try {
    return this.http.post(this.baseurl + uri, updatedOptions);
    // } catch (err) {
    //   console.log('-------Error occurred update posting----' + err);
    // }
  }

  deleteAcademicPrograms(academicProgramsid) {
    console.log('---delete is called----');
    return this.http.post(this.baseurl + '/deleteacademicPrograms/' + academicProgramsid, {});
  }

  private handleError(err: HttpErrorResponse) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
}
