<div *ngIf="promoCodeData" class="seneca-list-container">
  <mat-card id="main-mat-card">
    <mat-card-title>
      <h2 class="card-title">Promo Code Details</h2>
    </mat-card-title>
    <div class="mat-content">
      <form #promoCodeForm="ngForm" [formGroup]="PromoCodeForm" (ngSubmit)="savePromoCodeDetails(promoCodeForm.value)" class="form-container">
        <!-- <span class="id-title">
          Promo Code: {{ promoCode }}</span> -->
          <input matInput formControlName="newCode" value="{{ promoCodeData.NewCode }}" hidden />
        <div id="rowOne">
          <mat-form-field id="promoCode" appearance="outline">
            <mat-label>Promo Code</mat-label>
            <input matInput placeholder="Promo Code *" formControlName="promoCode" value="{{ promoCodeData.PromoCode }}" required readonly />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Promo Code Limit</mat-label>
            <input matInput placeholder="Promo Code Limit" formControlName="promoCodeLimit" value="{{ promoCodeData.PromoCodeLimit }}" required />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Redeemed</mat-label>
            <input matInput placeholder="Promo Code Usage" formControlName="promoCodeUsage" value="{{ promoCodeData.PromoCodeUsage }}" required readonly/>
          </mat-form-field>
        </div>

        <div id="rowTwo">
          <mat-form-field id="Description" appearance="outline">
            <mat-label>Description</mat-label>
            <input matInput placeholder="Description *" formControlName="description" [(ngModel)]="promoCodeData.Description" required />
          </mat-form-field>
        </div>
        <div id="rowThree">
          <mat-form-field appearance="outline" class="buttonSpacing">
            <input matInput [matDatepicker]="startDate" placeholder="Start Date *"
                formControlName="startDate" [(ngModel)]=" promoCodeData.StartDate "
                [min]="startDate" ng-model-options="{timezone: 'UTC'}">
                <mat-label>Start Date *</mat-label>
            <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
            <mat-datepicker #startDate></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <input matInput [matDatepicker]="endDate" placeholder="End Date *"
                formControlName="endDate" [min]="startDate" [(ngModel)]=" promoCodeData.StopDate "
                ng-model-options="{timezone: 'UTC'}">
                <mat-label>End Date *</mat-label>
            <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
            <mat-datepicker #endDate></mat-datepicker>
          </mat-form-field>
          <div id="rowFour">
            <mat-form-field id="ModifiedBy" appearance="outline">
              <mat-label>Modified By</mat-label>
              <input matInput placeholder="Modified By *" formControlName="modifiedBy" value="{{ promoCodeData.ModifiedBy }}" required readonly />
            </mat-form-field>

          <mat-form-field appearance="outline">
            <input matInput placeholder="Modified Date *"
                formControlName="modifiedDate" value="{{ promoCodeData.ModifiedDate }}"
                ng-model-options="{timezone: 'UTC'}" readonly/>
                <mat-label>Modified Date</mat-label>
          </mat-form-field>
          </div>

        </div>
        <div class="actions-box">
          <mat-card-actions>
            <div class="left-buttons">
            </div>
            <div class="right-buttons">
              <button mat-stroked-button type="button" class="seneca-button red" color="primary" (click)="goBack()">
                <span>Cancel</span>
              </button>
              <button type="submit" mat-flat-button class="seneca-button" [disabled]="promoCodeForm.invalid"
                color="primary">
                <span>Save</span>
              </button>
            </div>
          </mat-card-actions>
        </div>
      </form>
      <mat-card-content class="mat-elevation-z8"> </mat-card-content>
    </div>
  </mat-card>
</div>
