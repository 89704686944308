import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// import { ContentComponent } from './content.component';
// import { AdminAnnouncementComponent } from '../content/components/admin-announcements/admin-announcement/admin-announcement.component';
// import { AdminAnnouncementsComponent } from '../content/components/admin-announcements/admin-announcements.component';
// import { AdminNewsfeedComponent } from '../content/components/admin-newsfeeds/admin-newsfeed/admin-newsfeed.component';
// import { AdminNewsfeedsComponent } from '../content/components/admin-newsfeeds/admin-newsfeeds.component';
// import { AdminResourceComponent } from '../content/components/admin-resources/admin-resource/admin-resource.component';
// import { AdminResourcesComponent } from '../content/components/admin-resources/admin-resources.component';

import { AcademicProgramsComponent } from './components/academic-programs/academic-programs.component';
import { ApplicationsComponent } from './components/applications/applications.component';
import { BatchComponent } from './components/batch-control/batch.component';
import { CountryRestrictionComponent } from './components/country-restriction/country-restriction.component';
import { CountryRestrictionNewComponent } from './components/country-restriction/country-restriction-new/country-restriction-new.component';
import { FeeComponent } from './components/fee/fee.component';
import { PromoCodeComponent } from './components/promo-code/promo-code.component';
import { PromoCodeDetailsComponent } from './components/promo-code/promo-code-details/promo-code-details.component';
import { ViewApplicationComponent } from './components/applications/application-results/view-application/view-application.component';
import { FileContentComponent } from './components/applications/application-results/file-content/file-content.component';
import { ApplicationResultsComponent } from './components/applications/application-results/application-results.component';

export const iwaRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: 'academic-programs',
        pathMatch: 'full'
      },
      {
        path: 'academic-programs',
        children: [
          { path: '', component: AcademicProgramsComponent }
          //{ path: ':id', children: [{ path: '', component:  }
        ]
      },
      {
        path: 'applications',
        children: [
          { path: '', component: ApplicationsComponent },
          {
            path: '#:id', component: ViewApplicationComponent
          },
          {
            path: 'filecontent/:applicationId/:fileId', component: FileContentComponent
          },
          {
            path: 'getapplicationstatuslist', component: ApplicationResultsComponent
          },

        ]
      },
      {
        path: 'batch-control',
        children: [
          { path: '', component: BatchComponent }
        ]
      },
      {
        path: 'fee',
        children: [
          { path: '', component: FeeComponent }
        ]
      },
      {
        path: 'promo-code',
        children: [
          { path: '', component: PromoCodeComponent },
          { path: ':id', component: PromoCodeDetailsComponent }
        ]
      },
      {
        path: 'country-restriction',
        children: [
          { path: '', component: CountryRestrictionComponent },
          { path: ':id', component: CountryRestrictionNewComponent }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(iwaRoutes)],
  exports: [RouterModule]
})
export class IwaRoutingModule {
  public static GetNavLinks(): any[] {
    return [
      {
        label: 'Home',
        link: './home',
        iconName: 'home',
        isPanelHiddenParent: false,
        isPanelParent: false,
        index: 0
      },
      {
        label: 'Agents',
        link: './iwa/agents',
        iconName: 'person',
        isPanelHiddenParent: false,
        isPanelParent: false,
        index: 1
      },
      {
        label: 'IWA',
        link: './iwa/iwa',
        iconName: 'folder_shared',
        isPanelHiddenParent: false,
        isPanelParent: true,
        index: 2,
        children: [
          {
            label: 'academic-programs',
            link: './iwa/academic-programs',
            index: 0
          },
          {
            label: 'applications',
            link: './iwa/applications',
            index: 1
          },
          {
            label: 'batch',
            link: './iwa/batch-control',
            index: 2
          },
          {
            label: 'country-restriction',
            link: './iwa/country-restriction',
            index: 3
          },
          {
            label: 'fee',
            link: './iwa/fee',
            index: 4
          },
          {
            label: 'promo-code',
            link: './iwa/promo-code',
            index: 5
          }
        ]
      },

      {
        label: 'Commissions',
        link: './content/commissions',
        iconName: 'monetization_on',
        isPanelHiddenParent: false,
        isPanelParent: false,
        index: 3
      },

      {
        label: 'Content',
        link: './content/content',
        iconName: 'rss_feed',
        isPanelHiddenParent: false,
        isPanelParent: true,
        index: 4,
        children: [
          {
            label: 'Announcements',
            link: './content/announcements',
            index: 0
          },
          {
            label: 'Newsfeed',
            link: './content/newsfeed',
            index: 1
          },
          {
            label: 'Resources',
            link: './content/resources',
            index: 2
          }
        ]
      },

      {
        label: 'Agency',
        link: './agency',
        iconName: 'business',
        isPanelHiddenParent: false,
        isPanelParent: true,
        index: 5,
        children: [
          {
            label: 'Agencies',
            link: './agency/agencies',
            iconName: 'home',
            index: 0
          }
        ]
      },

      {
        label: 'Request',
        link: './content/request',
        iconName: 'pan_tool',
        isPanelHiddenParent: false,
        isPanelParent: false,
        index: 6
      },

      {
        label: 'System Admin',
        link: './content/system-admin',
        iconName: 'settings_applications',
        isPanelHiddenParent: false,
        isPanelParent: false,
        index: 7
      },
      {
        label: 'Logout',
        link: './content/logout',
        iconName: 'exit_to_app',
        isPanelHiddenParent: false,
        isPanelParent: false,
        index: 8
      }
    ];
  }
}
