import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpClientModule } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { IIwaApplication } from '../components/models/iwa-application.model';
import { BuildEnvironment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ApplicationFileContentService {
  private baseurl = BuildEnvironment.GetEnvironmentUrl() + '/applications';
  constructor(private http: HttpClient) { }
  getFileContent(applicationId: string, fileId: string, fileType: string) {
    console.log('ApplicationFileContentService getFileContent()');
    let resContentType: string = 'application/octet-stream';
    if (fileType) {
      if (fileType === 'jpg' || fileType === 'jpeg') {
        resContentType = 'image/jpeg';
      } else if (fileType === 'png') {
        resContentType = 'image/png';
      } else if (fileType === 'tif' || fileType === 'tiff') {
        resContentType = 'image/tiff';
      } else if (fileType === 'gif') {
        resContentType = 'image/gif';
      } else if (fileType === 'pdf') {
        resContentType = 'application/pdf';
        // res.set('Content-Type', 'application/octet-stream');
      }
    }
    //this.http.head().map()
    // return this.http.get(this.baseurl + '/getfiledetails/' + applicationId + "/" + fileId + "/" + fileType, { responseType: 'blob' });
    return this.http.get(this.baseurl + '/getfiledetails/' + applicationId + "/" + fileId + "/" + fileType, { headers: { "Accept": resContentType }, responseType: 'blob' });
  }
}
