import { Component, OnInit, ViewChild, Input, OnChanges } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, Sort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { CountryRestrictionService } from '../../../services/country-restriction.service';
import { DialogService } from '../../../services/dialog.service';

@Component({
  selector: 'app-country-restriction-list',
  templateUrl: './country-restriction-list.component.html',
  styleUrls: [
    '../../../../content/shared/css/commonList.css',
    '../../../../content/shared/css/commonAddNew.css',
    './country-restriction-list.component.scss'],
  providers: [DialogService]
})
export class CountryRestrictionListComponent implements OnChanges {

  @Input() term;
  ELEMENT_DATA: any[];
  selection;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns: string[] = ['select', 'Country_Name', 'STRM', 'PROG_DESCR', 'ACAD_PROG', 'CampusName', 'CreatedBy', 'CreatedDate'];
  dataSource: MatTableDataSource<any>;

  constructor(  private countryRestrictionService: CountryRestrictionService,
                private dialogService: DialogService) {}

  ngOnChanges() {
    if (this.term > 0) {
      this.countryRestrictionService.getCountryRestrictionList4Term(this.term).subscribe(data => {
        this.ELEMENT_DATA = data;
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);

        this.dataSource.sort = this.sort;
        this.selection = new SelectionModel<any>(true, []);

        const sortState: Sort = { active: 'Country_Name', direction: 'asc' };
        this.sort.active = sortState.active;
        this.sort.direction = sortState.direction;
        this.sort.sortChange.emit(sortState);

        this.paginator.pageSize = 10;

        this.dataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            default:
              return item[property] ? item[property].toLowerCase() : null;
          }
        };
        this.dataSource.paginator = this.paginator;

        //console.log(this.ELEMENT_DATA)
      }, error => {
        
      }
      );
    }
    
  }

  deleteCountryRestrictions(){
    let AcademicProgramIds = [];
    let sel = this.selection.selected;

    sel.forEach(function (value) {
      AcademicProgramIds.push({
        COUNTRY_CODE: value.COUNTRY_CODE,
        ACAD_PROG: value.ACAD_PROG,
        CAMPUS: value.CAMPUS,
        STRM: value.STRM
      });
    });    

    const confResult=this.dialogService.openDialog(
      "confirmChanges",
      "Confirm Action", 
      "Confirm deleting of selected restrictions?", 
      'Cancel',
      'Submit'
    );

    confResult.afterClosed().subscribe(dialogResult =>{
      if(dialogResult){
        // call the API to delete the selected IDs
        this.countryRestrictionService.deletecountryrestrictions(AcademicProgramIds).subscribe(res => {
          if (res) {
            this.dialogService.MessageBox('Deleted', 'X', 2000, 'SuccessMessage');
            this.refreshPage(2000);              
          } else {
            this.dialogService.MessageBox('Error updating record', 'X', 5000, 'ErrorMessage');
          }
        }, error => {
          const res = this.dialogService.ErrorDialog('Server Error', 'Sorry, the system is unavailable at the moment.', 'Close', 'Try Again');
          res.afterClosed().subscribe(dialogResult => {
            if (dialogResult) {
            }
          });
        });
      }
    });

    

  }

  refreshPage(time) {
    setTimeout(() => {
      this.ngOnChanges();
    }, time);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {    
    try{
    const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    } catch(e){}
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    else if(!this.selection === undefined){
      return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
    }
  }

  selectionHasValue(){
    if(!this.selection === undefined){
      return this.selection.hasValue();
    }
    else{
      return false;
    }
  }

  // logDebug(e?) {
  //   console.log('e', e);
  // }

}
