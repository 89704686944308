import { Component, OnInit, ViewChild } from '@angular/core';

import { MatPaginator, MatSort, MatTableDataSource, Sort } from '@angular/material';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent, DialogModel } from '../../../content/shared/dialog/dialog.component';

import { PromoCodeService } from '../../services/promocode.service';

@Component({
  selector: 'app-promo-code',
  templateUrl: './promo-code.component.html',
  styleUrls: ['./promo-code.component.scss', '../../../content/shared/css/commonList.css']
})
export class PromoCodeComponent implements OnInit {
  ELEMENT_DATA: any[];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns: string[] = ['PromoCode', 'Description', 'StartDate', 'StopDate', 'PromoCodeLimit', 'Redeemed', 'ModifiedBy', 'ModifiedDate'];
  dataSource: MatTableDataSource<any>;

  // status = [{ id: 0, value: 'Status' }, { id: 1, value: 'Active' }, { id: 2, value: 'Closed' }];

  constructor(private service: PromoCodeService, public dialog: MatDialog) { }
  ngOnInit() {
    this.refreshData();
    this.paginator.pageSize = 10;
  }
  addNewPromoCode() {
    let newPromoCode = 'SENE' + Math.random().toString().slice(2).slice(0, 10);
    // console.log('addNewPromoCode() ' + newPromoCode);
  }
  refreshData() {
    this.service.getAllpromoCodes().subscribe((promoCode: any[]) => {
      this.ELEMENT_DATA = promoCode;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'promoCode': return item.promoCode;
          case 'description': return item.description;
          case 'startDate': return item.startDate;
          case 'endDate': return item.endDate;
          case 'PromoCodeLimit': return item.PromoCodeLimit;
          case 'Redeemed': return item.Redeemed;
          case 'modifiedBy': return item.modifiedBy;
          case 'modifiedDate': return item.modifiedDate;
          default: return item[property];
        }
      };
      this.dataSource.paginator = this.paginator;
    });
  }

  applyFilter(filterValue: string) {
    // console.log('allyFilter() ' + filterValue);
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  deletePost(id: number) {
    this.service.deletePromoCode(id).subscribe(() => {
      this.refreshData();
    });
  }

  //--------------dialog-------//
  openDialog(id): void {
    const message = `Are you sure you want to delete?`;
    const resDialog = new DialogModel('Confirm Action', message);

    const dialogRef = this.dialog.open(DialogComponent, {
      data: resDialog,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.deletePost(id);
      }
    });
  }
}
