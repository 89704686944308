import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpClientModule } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { BuildEnvironment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CountryRestrictionService {

  private baseurl = BuildEnvironment.GetEnvironmentUrl() + '/countryrestriction';
  constructor(private http: HttpClient) { }

  getCountryRestrictionList4Term(acadTerm: number): Observable<any> {
    return this.http.get(this.baseurl + '/getcountyrestrictions4term/' + acadTerm);
  }

  deletecountryrestrictions(data): Observable<any> {
    return this.http.post(this.baseurl + '/deletecountryrestrictions/', data);
  }

  getAllCountryList(): Observable<any> {
    return this.http.get(this.baseurl + '/getallcountrieslist/');
  }

  getAcadPrograms(acadTerm: number): Observable<any> {
    return this.http.get(this.baseurl + '/getprogramlist/' + acadTerm);
  }

  addCountryrestrictions(data): Observable<any> {
    return this.http.post(this.baseurl + '/addnewcountryrestrictions/', data);
  }

}
