<div class="seneca-list-container ">
  <!--
  <mat-card class="main-container">

    <mat-card-title>
      <h1 class="card-title">Applications</h1>
    </mat-card-title>

    <div id="applications">
      <form #applications="ngForm" id="applications-search" [formGroup]="SearchForm">

        <mat-form-field id="appNumber" appearance="outline">
          <mat-label>Application Number</mat-label>
          <input matInput #search maxlength="11" placeholder="Application Number" formControlName="appNumber"/>
        </mat-form-field>

        <div id="or">
          <span> <b>- OR - </b></span>
        </div>

        <mat-form-field id="status" appearance="outline">
          <mat-label>Status</mat-label>
          <mat-select matInput placeholder="Status" required>
            <ng-container>
              <mat-option>--</mat-option>
              <mat-option *ngFor="let st of status" [value]="st.id" > {{st.value}}</mat-option>
            </ng-container>
          </mat-select>

        </mat-form-field>

        <mat-form-field id="term" appearance="outline">
          <mat-label>Term</mat-label>
          <input matInput placeholder="Term" required/>

        </mat-form-field>

        <mat-form-field id="submitStatus" appearance="outline">
          <mat-label>Error on submit</mat-label>
          <mat-select matInput placeholder="Error on Submit" required>
            <ng-container>
              <mat-option>--</mat-option>
              <mat-option *ngFor="let st of submitStatus" [value]="st.id"> {{st.value}}</mat-option>
            </ng-container>
          </mat-select>

        </mat-form-field>

        <div class="actions-box">
          <mat-card-actions>

            <button type="button" mat-flat-button class="seneca-button" color="primary"
              (click)="searchApplication(null)" onclick="this.blur()">
              <span>Search</span>
            </button>
          </mat-card-actions>
        </div>


      </form>
    </div>

  </mat-card>
   -->
</div>
<div #vc>
  <app-application-results *ngIf="loadApplication" [applicationSearch]="application"></app-application-results>
</div>
