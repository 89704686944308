import { Component, OnInit } from '@angular/core';
import { AuthenticationService} from '../../../shared/auth/auth.service';

@Component({
  selector: 'app-academic-programs',
  templateUrl: './academic-programs.component.html',
  styleUrls: ['../../../content/shared/css/commonList.css', './academic-programs.component.scss']
})
export class AcademicProgramsComponent implements OnInit {
  loadProgram: boolean;
  term = '';
  isIWAAdmin: boolean = false;
  constructor(private authService: AuthenticationService) {}

  ngOnInit() {
    this.isIWAAdmin = this.authService.hasRole("IWA_Admin");
    this.loadProgram = true;
    // defaults to next term and the Load from IWA Database option - 2
    this.term = this.getDefaultTerm().concat('2');
  }

  loadPrograms(term,pButton) {
    this.term = term.concat(pButton);
    this.loadProgram = true;
  }

  private getDefaultTerm(): string{

    const date: Date = new Date();
    let normalAppliedMonths = [0, 4, 8];
    const nextTermMonth = normalAppliedMonths.find(month => month >= date.getMonth());
    let year = date.getFullYear().toString();
    let month = nextTermMonth ? nextTermMonth : normalAppliedMonths[0];
    let strm = '';

    let y = year.substring(0, 1);

    if (year.substring(1, 2) === '0') {
        y = y + year.substring(2, 4);
    }
    else {
        y = y + year.substring(1, 4);
    }

    if (month === 0) {
        strm = y + '1';
    }
    else if (month === 4) {
        strm = y + 4;
    }
    else if (month === 8) {
        strm = y + '7';
    }
    return strm;
  }
}
