export interface IApplicationFile {
  Idx: number;
  Document_Type: string;
  Upload_Date: Date;
  Document_Name: string;
  Document_Id: string;
  FileType: string;
}
export class Applicationfile implements IApplicationFile {
  Idx = 0;
  Document_Type = '';
  Upload_Date = null;
  Document_Name = '';
  Document_Id = '';
  FileType = '';
}
