import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../../shared/material.module';
import { SharedModule } from '../../../shared/shared.module';
import { BatchComponent } from './batch.component';

@NgModule({
  declarations: [BatchComponent],
  imports: [
    CommonModule, MaterialModule, FormsModule,
    ReactiveFormsModule, HttpClientModule, SharedModule,
  ],
  exports: [BatchComponent, MaterialModule]
})
export class BatchControlModule { }
