import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Component, Inject } from "@angular/core";

@Component({
  selector: 'icon-snack-bar',
  template: `<div class="flex-box">
    <div class="flex-column">
        <img src="../../../../assets/img/check_circle_outline-24px_rounded.svg" />
    </div>
    &nbsp;<div class="flex-column">{{ data?.message }}</div>
  </div>`
})

export class IconSnackBarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }
}