export interface IApplicationChoice {
  Idx: number;
  ChoiceNumber: number;
  StartDate: Date;
  ProgramCode: string;
  ProgramCampus: string;
  AcadPlan: string;
  AcadCareer: string;
  Strm: number;
  ActionReason: string;
}
export class ApplicationProgChoice implements IApplicationChoice {
  Idx = 0;
  ChoiceNumber = 0;
  StartDate = null;
  ProgramCode = '';
  ProgramCampus = '';
  AcadPlan = '';
  AcadCareer = '';
  Strm = 0;
  ActionReason = '';
}
