<div class="seneca-container" id="header">


  <mat-sidenav-container class="seneca-sidenav-container" autosize>
    <mat-sidenav #drawer [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="!(isHandset$ | async)"
      [ngClass]="{'sideNavExpanded':isExpanded, 'sideNavCollapsed': !isExpanded, 'sideNavTouch': isHandset$ | async}">
      <mat-toolbar color="primary" id="top-sidenav" *ngIf="isHandset$ | async">
        <mat-toolbar-row>
          <button mat-icon-button class="seneca-header-menu" (click)="drawer.toggle(); isExpanded = true"
            onclick="this.blur()" autofocus=false>
            <mat-icon>menu</mat-icon>
          </button>
          ADMIN PORTAL
        </mat-toolbar-row>
      </mat-toolbar>
      <div class="divider-20" cdk-focus-start></div>
      <mat-nav-list *ngFor="let link of navLinks">
        <mat-list-item *ngIf="!showPanelItem(link, isExpanded)" routerLinkActive="active-link"
          matTooltip="{{link.label}}" matTooltipPosition="right" [matTooltipDisabled]="isExpanded">
          <mat-icon mat-list-icon [routerLink]="link.link">

            {{ link.iconName }}</mat-icon>
          <span mat-line *ngIf="isExpanded" [routerLink]="link.link">{{ link.label }}</span>
        </mat-list-item>

        <mat-expansion-panel class="mat-elevation-z0 " *ngIf="showPanelItem(link, isExpanded)"
          routerLinkActive="active-link" [expanded]="(activeLinkUrl)==link.link ">
          <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
            <mat-panel-title>
              <mat-icon mat-list-icon>{{ link.iconName }}</mat-icon>
              <div class="mat-padding-left">
                <span mat-line *ngIf="isExpanded">{{ link.label }}</span>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <span *ngFor="let childLink of link.children">
            <a [routerLink]="childLink.link" routerLinkActive="active-link">
              <mat-list-item>
                <div class="mat-expansion-panel-padding-left"></div>
                {{ childLink.label }}
              </mat-list-item>
            </a>
          </span>
        </mat-expansion-panel>
      </mat-nav-list>
    </mat-sidenav>
    <!-- *ngIf="_router.url.includes('content')" -->
    <mat-sidenav-content autosize>
      <mat-toolbar class="seneca-header" color="primary">
        <mat-toolbar-row class="seneca-header-row">
          <span class="header-left-item">
            <div *ngIf="!(isHandset$ | async)">
              <button mat-icon-button class="seneca-header-menu" (click)="isExpanded = !isExpanded; drawer.open()"
                onclick="this.blur()">
                <mat-icon>menu</mat-icon>
              </button>
            </div>
            <div *ngIf="isHandset$ | async">
              <button mat-icon-button class="seneca-header-menu" (click)="drawer.toggle(); isExpanded = true"
                onclick="this.blur()">
                <mat-icon>menu</mat-icon>
              </button>
            </div>
            <div class="seneca-header-logo">
              <img src="../../../../assets/img/Seneca-International-Horizontal-Logo.svg" (click)="onHomeClick()" />
            </div>
          </span>
          <div class="header-right-item">
            ADMIN PORTAL
          </div>
        </mat-toolbar-row>
      </mat-toolbar>
      <ng-content></ng-content>
      <app-back-to-top [acceleration]="2" [animate]="true" [scrollDistance]="50" [speed]="50">
      </app-back-to-top>
      <span id="footer">
        <mat-toolbar>
          <mat-toolbar-row class="seneca-footer-row">
            <div class="seneca-footer-logo">
              <img class="seneca-logo" src="../../../../assets/img/seneca-logo-red.png" />
            </div>

            <div class="seneca-footer-middle">
              <span>© 2020. All rights reserved.</span>
            </div>
          </mat-toolbar-row>
        </mat-toolbar>
      </span>



    </mat-sidenav-content>

  </mat-sidenav-container>
</div>