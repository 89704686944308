<mat-card id="main-mat-card">
  <mat-card-title id="application-number">
    <h2 class="card-title">Application Number: {{ applicationNum.Application_Id }}</h2>
  </mat-card-title>

  <mat-card-content id="view-application-content">
   
    <div id="update-application">
      <button mat-flat-button type="button" [disabled]= "applicationNum.Application_Status !== 'paid' || psBtnDisabled" class="seneca-button" color="primary"
      (click)="submitAppToPS(applicationNum.Application_Id)">
      Submit to PeopleSoft  
      </button>
      <button mat-flat-button type="button" [disabled]= "applicationNum.Application_Status !== 'submitted' || psDocsBtnDisabled" class="seneca-button" color="primary"
      (click)="submitDocsToPS(applicationNum.Application_Id)">
      Submit Documents  
      </button>
      <span id="update-title"> <b>APPLICATION DETAILS</b></span>

      <form #applications="ngForm" class="application-form">

        <div id="update-rows">

          <mat-form-field class="update-fields" appearance="outline">
            <mat-label>Application ID</mat-label>
            <input matInput value="{{ applicationNum.Application_Id }}" readonly />
          </mat-form-field>
          <mat-form-field class="update-fields" appearance="outline">
            <mat-label>Birthdate</mat-label>
            <input matInput placeholder="Birthdate" value="{{ applicationNum.BirthDate }}" readonly/>
          </mat-form-field>
          <!--
          <mat-form-field class="update-fields" appearance="outline">
            <mat-label>I Am</mat-label>
            <mat-select matInput placeholder="I Am">
              <ng-container>
                <mat-option selected>RF</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          -->
          <mat-form-field class="update-fields" appearance="outline">
            <mat-label>Application Status</mat-label>
            <mat-select #statusSelect matInput placeholder="Status" [value]="applicationNum.Application_Status" required>
                <mat-option *ngFor="let st of status" [value]="st"> {{st}}</mat-option>
            </mat-select>
            <!--
            <mat-select matInput placeholder="WS Submit Error">
              <ng-container>
                <mat-option>N - No</mat-option>
                <mat-option>Y - Yes</mat-option>
              </ng-container>
            </mat-select>
            -->
            <!-- <input matInput placeholder="Application Status"  value="{{ applicationNum.Application_Status }}" readonly/> -->
          </mat-form-field>
          <mat-form-field class="update-fields" appearance="outline">
            <mat-label>Gender</mat-label>
            <input matInput value="{{ applicationNum.Gender }}" readonly />
          </mat-form-field>
          <mat-form-field class="update-fields" appearance="outline">
            <mat-label>Seneca ID</mat-label>
            <input matInput placeholder="Seneca ID" value="{{ applicationNum.SENECA_ID }}" readonly />
          </mat-form-field>
          <mat-form-field class="update-fields" appearance="outline">
            <mat-label>Citizenship</mat-label>
            <input matInput  value="{{ applicationNum.Citizenship }}" readonly/>
          </mat-form-field>
          <mat-form-field class="update-fields" appearance="outline">
            <mat-label>First Language Code</mat-label>
            <input matInput value="{{ applicationNum.PrimaryLang }}" readonly />
          </mat-form-field>

          <mat-form-field class="update-fields" appearance="outline">
            <mat-label>Fee Status</mat-label>
            <input matInput placeholder="Fee Status" value="{{ applicationNum.PayStatus }}" readonly/>
          </mat-form-field>
          <mat-form-field class="update-fields" appearance="outline">
            <mat-label>Personal Email</mat-label>
            <input matInput value="{{ applicationNum.Email }}" readonly />
          </mat-form-field>


          <mat-form-field class="update-fields" appearance="outline">
            <mat-label>Fee Date</mat-label>
            <input matInput placeholder="Fee Date" value="{{ applicationNum.PaymentDate.toString() }}" readonly/>
            <!-- <input matInput [matDatepicker]="pickerFee" placeholder="Fee Date" />
            <mat-datepicker-toggle matSuffix [for]="pickerFee"></mat-datepicker-toggle>
            <mat-datepicker #pickerFee></mat-datepicker>
            -->
          </mat-form-field>

          <mat-form-field class="update-fields" appearance="outline">
            <mat-label>Adm Appl Nbr</mat-label>
            <input matInput placeholder="Adm Appl Nbr" value="{{ applicationNum.ADM_APPL_NBR }}" readonly/>
          </mat-form-field>

          <mat-form-field class="update-fields" appearance="outline">
            <mat-label>PS_SCC_TEMP_ID</mat-label>
            <input matInput placeholder="PS_SCC_TEMP_ID"  value="{{ applicationNum.PS_SCC_TEMP_ID }}" readonly/>
          </mat-form-field>
          <mat-form-field class="update-fields" appearance="outline">
            <mat-label>ELI Adm Appl Nbr</mat-label>
            <input matInput placeholder="ADM_APPL_NBR_ELI" value="{{ applicationNum.ADM_APPL_NBR_ELI }}" readonly/>
          </mat-form-field>

          <mat-form-field class="update-fields" appearance="outline">
            <mat-label>PS_SCC_TEMP_ID_ELI</mat-label>
            <input matInput placeholder="PS_SCC_TEMP_ID_ELI"  value="{{ applicationNum.PS_SCC_TEMP_ID_ELI }}" readonly/>
          </mat-form-field>


          <mat-form-field class="update-fields" appearance="outline">
            <mat-label>Promo Code</mat-label>
            <input #promoCodeField matInput placeholder="Promo Code"  value="{{ applicationNum.PromoCode }}" [readonly]="applicationNum.Step_Progression < 7 || applicationNum.Application_Status !== 'new'"/>
          </mat-form-field>
          <mat-form-field class="update-fields" appearance="outline">
            <mat-label>Agent ID</mat-label>
            <input matInput placeholder="Agent ID"  value="{{ applicationNum.Agent_ID }}" readonly/>
          </mat-form-field>
          <button type="button" mat-flat-button class="seneca-button" color="primary"
          [disabled]="applicationNum.Application_Status == statusSelect.value"
          (click)="saveApplication(statusSelect, applicationNum.Application_Id)"
          >Update Status</button>
          <button mat-flat-button type="button" [disabled]= "applicationNum.Step_Progression < 7 || applicationNum.Application_Status !== 'new'" class="seneca-button" color="primary"
      (click)="markApplicationAsPaid(applicationNum.Application_Id, promoCodeField.value)">
      Mark as Paid  
      </button>


          <!--
          <mat-form-field class="update-fields" appearance="outline">
            <mat-label>Method of Payment</mat-label>
            <mat-select matInput placeholder="Method of Payment">
              <ng-container>
                <mat-option>C - Credit Card</mat-option>
                <mat-option>D - Debit Card</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="update-fields" appearance="outline">
            <mat-label>Status</mat-label>
            <mat-select matInput placeholder="Status">
              <ng-container>
                <mat-option>5 - Data copied to PS-STG</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="update-fields" appearance="outline">
            <mat-label>Duplicate</mat-label>
            <mat-select matInput placeholder="Duplicate">
              <ng-container>
                <mat-option>N - No</mat-option>
                <mat-option>Y - Yes</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="update-fields" appearance="outline">
            <mat-label>Registered Agent</mat-label>
            <mat-select matInput placeholder="Registered Agent">
              <ng-container>
                <mat-option>N </mat-option>
                <mat-option>Y </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="update-fields" appearance="outline">
            <mat-label>Sen Prev Flag</mat-label>
            <mat-select matInput placeholder="Sen Prev Flag">
              <ng-container>
                <mat-option>N </mat-option>
                <mat-option>Y </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="update-fields" appearance="outline">
            <mat-label>Direct Mail</mat-label>
            <mat-select matInput placeholder="Sen Prev Flag">
              <ng-container>
                <mat-option>N </mat-option>
                <mat-option>Y </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="update-fields" appearance="outline">
            <mat-label>New Agent</mat-label>
            <mat-select matInput placeholder="New Agent">
              <ng-container>
                <mat-option>N </mat-option>
                <mat-option>Y </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          -->
        </div>
        <!--
        <div id="last-update-row">

          <mat-form-field class="update-fields empty" appearance="outline">
            <mat-label>First Semester</mat-label>
            <input matInput value="{{ applicationNum.FirstSemester }}" readonly />
          </mat-form-field>

          <mat-form-field class="update-fields" appearance="outline">
            <mat-label>Relative/Friend</mat-label>
            <mat-select matInput placeholder="Relative/Friend">
              <ng-container>
                <mat-option>N </mat-option>
                <mat-option>Y </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
        -->
        <div id="applProgChoices" *ngIf="applicationProgChoices && applicationProgChoices.length > 0 else programChoiceMessage">
          <span id="prog-choice-title"> <b>Application Program Choices</b></span>

          <table id="progChoices-table" mat-table [dataSource]="applicationProgChoices">
            <ng-container matColumnDef="idx">
              <th mat-header-cell  *matHeaderCellDef> No. </th>
              <td mat-cell *matCellDef="let choice"> {{choice.Idx}} </td>
            </ng-container>
            <ng-container matColumnDef="choiceNumber">
              <th mat-header-cell *matHeaderCellDef> Choice </th>
              <td mat-cell *matCellDef="let choice"> {{choice.ChoiceNumber}} </td>
            </ng-container>

            <ng-container matColumnDef="term">
              <th mat-header-cell *matHeaderCellDef> Term </th>
              <td mat-cell *matCellDef="let choice"> {{choice.Strm}} </td>
            </ng-container>
            <ng-container matColumnDef="startDate">
              <th mat-header-cell *matHeaderCellDef> Start Date </th>
              <td mat-cell *matCellDef="let choice"> {{choice.StartDate}} </td>
            </ng-container>
            <ng-container matColumnDef="progCode">
              <th mat-header-cell *matHeaderCellDef> Program Code </th>
              <td mat-cell *matCellDef="let choice">
                {{choice.ProgramCode}}
            </ng-container>
            <ng-container matColumnDef="progCampus">
              <th mat-header-cell *matHeaderCellDef> Program Campus </th>
              <td mat-cell *matCellDef="let choice"> {{choice.ProgramCampus}} </td>
            </ng-container>
            <ng-container matColumnDef="actionReason">
              <th mat-header-cell *matHeaderCellDef> Action Reason </th>
              <td mat-cell *matCellDef="let choice"> {{choice.ActionReason}} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="ProgChoiceColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: ProgChoiceColumns"></tr>

          </table>
        </div>
        <ng-template #programChoiceMessage>
          <span id="prog-choice-title"> <b>{{ProgChoiceMessage}}</b></span>
        </ng-template>


      </form>

    </div>

    <div id="documents" *ngIf="applicationFiles && applicationFiles.length > 0; else filesMessage">
      <span id="documents-title"> <b> View Documents</b></span>
      <table id="documents-table" mat-table [dataSource]="applicationFiles" >
        <ng-container matColumnDef="idx">
          <th mat-header-cell *matHeaderCellDef> No. </th>
          <td mat-cell *matCellDef="let element"> {{element.Idx}} </td>
        </ng-container>

        <ng-container matColumnDef="documentType">
          <th mat-header-cell *matHeaderCellDef> Document Type </th>
          <td mat-cell *matCellDef="let element"> {{element.Document_Type}} </td>
        </ng-container>
        <ng-container matColumnDef="loadDate">
          <th mat-header-cell *matHeaderCellDef> Load Date </th>
          <td mat-cell *matCellDef="let element"> {{element.Upload_Date}} </td>
        </ng-container>
        <ng-container matColumnDef="fieldLocation">
          <th mat-header-cell *matHeaderCellDef> Document Id </th>
          <td mat-cell *matCellDef="let element">
            {{element.Document_Id}}
        </ng-container>
        <ng-container matColumnDef="fileName">
          <th mat-header-cell *matHeaderCellDef> Filename </th>
          <td mat-cell *matCellDef="let element"> {{element.Document_Name}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"  (click)="getFileContent(applicationNum.Application_Id,row.Document_Id,row.FileType)"></tr>
      </table>
    </div>
    <ng-template #filesMessage>{{ applicationNum.FilesMessage }}</ng-template>
    <div id="update-footer">

      <div class="actions-box">
      <!--
        <button mat-stroked-button class="is-rounded red" color="primary" [routerLink]="'/'">
          <span>Cancel</span>
        </button>

        <button type="submit" mat-flat-button class="is-rounded" color="primary">
          <span>Update</span>
        </button>
      -->
      </div>
    </div>

  </mat-card-content>


</mat-card>
