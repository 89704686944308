import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { IwaComponent } from '../../../iwa.component';
import { MaterialModule } from '../../../../shared/material.module';
import { SharedModule } from '../../../../shared/shared.module';
import { IwaRoutingModule } from '../../../iwa-routing.module';
import { ApplicationsComponent } from '../applications.component';
import { ApplicationResultsComponent } from '../application-results/application-results.component';
import { ViewApplicationComponent } from '../application-results/view-application/view-application.component';
import { FileContentComponent } from '../application-results/file-content/file-content.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ImageViewerModule } from 'ng2-image-viewer';
import { AngularFileViewerModule } from '@taldor-ltd/angular-file-viewer';


@NgModule({
  declarations: [ApplicationsComponent, ApplicationResultsComponent, ViewApplicationComponent,
    FileContentComponent],
  imports: [
    MaterialModule, FormsModule,
    ReactiveFormsModule, HttpClientModule, SharedModule,
    CommonModule, IwaRoutingModule
    , PdfViewerModule
    , NgxExtendedPdfViewerModule
    , ImageViewerModule
    , AngularFileViewerModule
  ],
  bootstrap: [],
  exports: [ApplicationsComponent],
  entryComponents: [ApplicationResultsComponent,
    ViewApplicationComponent,]
})
export class ApplicationsListModule { }
