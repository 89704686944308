<div class="seneca-list-container">
  <mat-card class="main-container">
    <mat-card-title>
      <h1 class="card-title">Promo Code</h1>
      <div class="actions-box">
        <mat-form-field appearance="outline" class="search-bar">
          <mat-label>Search</mat-label>
          <input matInput #search (keyup)="applyFilter($event.target.value)" maxlength="256" placeholder="Search" />
          <mat-icon matSuffix>search</mat-icon>

          <mat-hint align="start">Example: Promo Code, Description </mat-hint>
        </mat-form-field>
      </div>
    </mat-card-title>
    <mat-card-actions class="top-actions">
      <div class="left-buttons"></div>
      <div class="right-buttons">
        <button mat-flat-button class="seneca-button" color="primary" [routerLink]="['../0']">
          <mat-icon>add</mat-icon> <span>Add New</span>
        </button>
      </div>
    </mat-card-actions>

    <mat-card-content class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort>
        <!--- Note that these columns can be defined in any order.
                        The actual rendered columns are set as a property on the row definition" -->

        <ng-container matColumnDef="PromoCode">
          <th class="priority-5 addSpace" mat-header-cell *matHeaderCellDef mat-sort-header>
            Promo Code
          </th>
          <td class="priority-5" mat-cell *matCellDef="let element"> {{ element.PromoCode }} </td>
        </ng-container>

        <ng-container matColumnDef="Description">
          <th class="priority-2" mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
          <td class="priority-2" mat-cell *matCellDef="let element"> {{ element.Description }} </td>
        </ng-container>

        <ng-container matColumnDef="StartDate">
          <th mat-header-cell class="priority-3" mat-header-cell *matHeaderCellDef mat-sort-header>Start Date</th>
          <td mat-cell class="priority-3"  *matCellDef="let element"> {{ element.StartDate  |  date: 'mediumDate'}} </td>
        </ng-container>

        <ng-container matColumnDef="StopDate">
          <th class="priority-4" mat-header-cell *matHeaderCellDef mat-sort-header>
            End Date
          </th>
          <td class="priority-4" mat-cell *matCellDef="let element">
            {{ element.StopDate  |  date: 'mediumDate'  }}
          </td>
        </ng-container>
        <ng-container matColumnDef="PromoCodeLimit">
          <th class="priority-4" mat-header-cell *matHeaderCellDef mat-sort-header>
            Limit
          </th>
          <td class="priority-4" mat-cell *matCellDef="let element">
            {{ element.PromoCodeLimit  }}
          </td>
        </ng-container>
        <ng-container matColumnDef="Redeemed">
          <th class="priority-4" mat-header-cell *matHeaderCellDef mat-sort-header>
            Redeemed
          </th>
          <td class="priority-4" mat-cell *matCellDef="let element">
            {{ element.Redeemed  }}
          </td>
        </ng-container>
        <ng-container matColumnDef="ModifiedBy">
          <th class="priority-2" mat-header-cell *matHeaderCellDef mat-sort-header>Modified By</th>
          <td class="priority-2" mat-cell *matCellDef="let element"> {{ element.ModifiedBy }} </td>
        </ng-container>
        <ng-container matColumnDef="ModifiedDate">
          <th class="priority-4" mat-header-cell *matHeaderCellDef mat-sort-header>
            Modified Date
          </th>
          <td class="priority-4" mat-cell *matCellDef="let element">
            {{ element.ModifiedDate  |  date: 'mediumDate'  }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" [routerLink]="['../', row.PromoCode]"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[5, 10, 20, 50, 100]" showFirstLastButtons> </mat-paginator>
    </mat-card-content>
  </mat-card>
</div>
