import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpClientModule } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { BuildEnvironment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class IwaFeeService {
  private baseurl = BuildEnvironment.GetEnvironmentUrl() + '/iwafee';
  constructor(private http: HttpClient) { }

  getIwaFee() {
    return this.http.get(this.baseurl + '/getiwafee');
  }

  updateIwaFee(formdata) {
    return this.http.post(this.baseurl + '/updateiwafee', formdata);
  }

  private handleError(err: HttpErrorResponse) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
}
