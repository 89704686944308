import { Component, OnChanges, Input, ViewChild, OnInit } from '@angular/core';
import { MatDialog, MatSort, MatTableDataSource, Sort } from '@angular/material';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { AcademicProgramsService } from '../../../services/academicPrograms.service';
import { IAcademicProgramStatus } from '../../models/lookup.model';
import { LookupsService } from '../../../services/lookup.service';
import { DialogService } from '../../../services/dialog.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../../../shared/auth/auth.service';
@Component({
  selector: 'app-academic-programs-list',
  templateUrl: './academic-programs-list.component.html',
  styleUrls: [
    '../../../../content/shared/css/commonList.css',
    '../../../../content/shared/css/commonAddNew.css',
    './academic-programs-list.component.scss'
  ],
  providers: [DialogService]
})
export class AcademicProgramsListComponent implements OnChanges {

  //if api call works change below with bulkStatuses: any[];
  bulkStatuses;
  selectedBulkOption;

  selectedGradTerm: string;

  //determine which number was pressed
  pageToLoad;
  pageRefresh = false;
  pressedButton = 0;
  @Input() term;
  mloaded = false;

  ELEMENT_DATA: any[];
  selection;
  TheSearchForm: FormGroup;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns: string[] = ['select', 'PROG_DESCR', 'ACAD_PROG', 'CAMPUS_DESC', 'DEGREE', 'PROG_LEN', 'EXP_GRAD_TERM', 'PROG_AVAILABILITY', 'ELI_CONDITIONAL', 'PATHWAY_CONDITIONAL', 'BUNDLE_ELIGIBLE', 'DEGREE_BRIDGE'];
  dataSource: MatTableDataSource<any>;

  programsFromCSLoading: boolean = false;
  isIWAAdmin: boolean = false;

  constructor(
    private acaPrmService: AcademicProgramsService,
    private lookupService: LookupsService,
    private dialogService: DialogService,
    private _formBuilder: FormBuilder,
    private authService: AuthenticationService
  ) {
    this.acaPrmService.getAllStatuses().subscribe(data => {
      this.bulkStatuses = data;
    });
    // console.log(this.bulkStatuses);
    if (Array.isArray(this.bulkStatuses)) {
      this.bulkStatuses.forEach(item => {
      });
    }

  }


  ngOnChanges() {

    this.isIWAAdmin = this.authService.hasRole("IWA_Admin");

    this.TheSearchForm = this._formBuilder.group({
      statusID: [0, null],
    });

    if (this.term) {
      // this.pressedButton = this.term % 10;
      this.pressedButton = this.term.substring(4, 5);
      let strm = this.term.substring(0, 4);
      this.term = strm;
      // this.term = Math.floor(this.term);
      // this.pageToLoad=this.pressedButton;

      if (this.pressedButton == 1) {//load programs from peoplesoft
        this.programsFromCSLoading = true;
        this.acaPrmService.getAllAcademicProgramsPS(strm).subscribe(data => {
          this.programsFromCSLoading = false;
          if (data && data.programsAdded === 0) {
            this.dialogService.MessageBox('No new programs were added', 'X', 10000, 'SuccessMessage');
          }
          else {
            this.dialogService.MessageBox(data.programsAdded + ' new programs were added', 'X', 10000, 'SuccessMessage');
            this.term = this.term.concat(2);
            this.callNextandRefresh(2000);
          }


        }, error => {
          const res = this.dialogService.ErrorDialog('Server Error', 'Sorry, the system is unavailable at the moment.', 'Close', 'Try Again');
          res.afterClosed().subscribe(dialogResult => {
            if (dialogResult) {
              this.callRepeat(this.term);
            }
          });
        }
        );
      } else if (this.pressedButton == 2) {//load from IWA Application
        this.acaPrmService.getAllAcademicProgram(strm).subscribe(data => {
          this.ELEMENT_DATA = data;
          this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
          this.selection = new SelectionModel<any>(true, []);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.acaPrmService.getAllStatuses().subscribe(data => {
            this.bulkStatuses = data;
          })
          // console.log(this.bulkStatuses);

          //console.log(this.ELEMENT_DATA)
        }, error => {
          const res = this.dialogService.ErrorDialog('Server Error', 'Sorry, the system is unavailable at the moment.', 'Close', 'Try Again');
          res.afterClosed().subscribe(dialogResult => {
            if (dialogResult) {
              this.callRepeat(this.term);
            }
          });
        }
        );
      }

    }
    // this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    // this.selection = new SelectionModel<any>(true, []);    
  }


  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    try {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    } catch (e) { }
  }

  selectionHasValue() {
    if (!this.selection === undefined) {
      return this.selection.hasValue();
    }
    else {
      return false;
    }
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  logDebug(e?) {
    //console.log('e', e);
  }

  callRepeat(term) {
    // console.log(term);
  }

  addToQueue() {

  }

  bulkPublish() {
    let AcademicProgramIds = [];
    let sel = this.selection.selected;
    let academicCampus = [];

    if (!this.selectedBulkOption) {
      //send error back to user askign to choose a bulk status
      const bulkErr = this.dialogService.openDialog(
        "confirmChanges",
        "Submission Error",
        "Please choose a status to change before publishing",
        'Cancel',
        'Ok'
      );
    } else {//determine which option has been selected from the bulk action dropdown
      if (this.selectedBulkOption) {
        sel.forEach(function (value) {
          AcademicProgramIds.push(value.ACAD_PROG);
          academicCampus.push(value.CAMPUS);
        });

        //make sure users selected programs
        if (AcademicProgramIds.length > 0) {
          const confResult = this.dialogService.openDialog(
            "confirmChanges",
            "Confirm Action",
            "Would you like to publish these changes?",
            'Cancel',
            'Submit'
          );

          //put all call options into an object to pass to backend
          let optionParams = [{
            acadPrograms: AcademicProgramIds,
            campus: academicCampus,
            action: this.selectedBulkOption,
            term: this.term
          }];

          //if user clicks submit push and commit changesgit
          confResult.afterClosed().subscribe(dialogResult => {
            if (dialogResult) {
              this.acaPrmService.updateAcademicBatch('/academicprogram/updateacadprograms/', optionParams).subscribe(
                res => {
                  if (res) {
                    this.dialogService.MessageBox('Published', 'X', 5000, 'SuccessMessage');
                    this.term = this.term.concat(2);
                    this.callNextandRefresh(2000);
                  } else {
                    this.dialogService.MessageBox('Error updating record', 'X', 5000, 'ErrorMessage');
                  }
                },
                error => {
                  const res = this.dialogService.ErrorDialog('Server Error', 'Sorry, the system is unavailable at the moment.', 'Close', 'Try Again');
                  res.afterClosed().subscribe(dialogResult => {
                    if (dialogResult) {
                    }
                  });
                }
              );
            }
          });
        } else {//if user didnt select any courses but still clicked publish button tell them to choose courses first
          const confResult = this.dialogService.openDialog(
            "confirmChanges",
            "Submission Error",
            "Please choose a program before publishing",
            'Cancel',
            'Ok'
          );
        }

      }
    }
  }

  expGradTermPublish() {
    console.log(this.selectedGradTerm);

    let sel = this.selection.selected;
    let endTerm = this.selectedGradTerm;
    let maxProgLen;
    let minEndTerm;

    if (!endTerm || endTerm == "") {
      const bulkErr = this.dialogService.openDialog(
        "confirmChanges",
        "Submission Error",
        "Please enter a term before publishing",
        'Cancel',
        'Ok'
      );
    } else if (endTerm.length < 4 || (!endTerm.endsWith('1') && !endTerm.endsWith('4') && !endTerm.endsWith('7'))) {
      const bulkErr = this.dialogService.openDialog(
        "confirmChanges",
        "Submission Error",
        "Invalid term. Please enter a valid term",
        'Cancel',
        'Ok'
      );
    } else {
      if (sel.length) { //if user selected program(s)
        maxProgLen = Math.max.apply(Math, sel.map(function (s) { return s.PROG_LEN; }))
        // minEndTerm = this.calcMinimumEndTerm(this.term, maxProgLen);

        console.log('Start Term: ' + this.term);
        console.log('Max Length: ' + maxProgLen);
        console.log('  Min Term: ' + minEndTerm);
        console.log('Entrd Term: ' + this.selectedGradTerm);

        //if (+minEndTerm <= +this.selectedGradTerm) { //if user entered a term that all selected programs can finish by
          const confResult = this.dialogService.openDialog(
            "confirmChanges",
            "Confirm Action",
            "Would you like to publish these changes?",
            'Cancel',
            'Submit'
          );

          confResult.afterClosed().subscribe(dialogResult => {
            if (dialogResult) {
              let formDataMap = sel.map(prog => {
                let body = {
                  endTerm: this.selectedGradTerm,
                  programCode: prog.ACAD_PROG,
                  startTerm: prog.STRM,
                  campus: prog.CAMPUS
                }

                console.log(body);
                return this.acaPrmService.updateAcademicBatch('/academicprogram/updateterms', body).subscribe(
                  res => {
                    //console.log(res);
                  }
                );
              });

              Promise.all(formDataMap).then(() => {
                this.dialogService.MessageBox('Published', 'X', 5000, 'SuccessMessage');
                this.term = this.term.concat(2);
                this.callNextandRefresh(2000);
              });
            }
          })
        /*} else { //if user selected an end term that is too soon for a program
          const selError = this.dialogService.openDialog(
            "confirmChanges",
            "Submission Error",
            "Expected Graduation Term is too soon for selected program(s). Please enter a different term.",
            'Cancel',
            'Ok'
          );
        }*/
      } else {//if user didnt select any courses but still clicked publish button tell them to choose courses first
        const selError = this.dialogService.openDialog(
          "confirmChanges",
          "Submission Error",
          "Please choose a program before publishing",
          'Cancel',
          'Ok'
        );
      }
    }

  }

  calcMinimumEndTerm(strTerm, progLen) {
    //program assumes a valid term was passed
    let rtTerm = +strTerm;
    for (let i = 0; i < progLen; i++) {
      let lastDigit = rtTerm % 10;
      if (lastDigit !== 7) {
        rtTerm += 3;
      } else {
        rtTerm += 4;
      }
    }
    return rtTerm.toString();
  }

  callNextandRefresh(time) {
    this.pageRefresh = true;
    setTimeout(() => {
      this.ngOnChanges();

    }, time);
  }

  //Update value to show proper credential value to user for each element
  getCred(element) {
    let progCredential;
    if (element === "OCGC") {
      progCredential = "Graduate Certificate";
    }
    else if (element === "OCD") {
      progCredential = "Diploma";
    }
    else if (element === "BD") {
      progCredential = "Bachelor's Degree";
    }
    else if (element === "OCAD") {
      progCredential = "Advanced Diploma";
    }
    else {
      progCredential = "Certificate";
    }
    return progCredential;
  }
  //Display correct campus
  /*getCampus(element) {
    let campus;
    if (element === "SY") {
      campus = "Seneca@York";
    }
    else if (element === "SIA") {
      campus = "Seneca International Academy";
    }
    else if (element === "NH") {
      campus = "Newnham";
    }
    else if (element === "MKM") {
      campus = "Markham";
    }
    else if (element === "OF") {
      campus = "Seneca Downtown";
    }
    else if (element === "KG") {
      campus = "King";  
    }
    else if (element === "YG") {
      campus = "Yorkgate";
    }
    else {
      campus = "NOTHING";
    }
    return campus;
  }*/
  //Update value to show program availability status
  getStatus(element) {
    let progStatus;
    if (element === "OP") {
      progStatus = "Open";
    }
    else if (element === "CL") {
      progStatus = "Closed";
    }
    else if (element === "WL") {
      progStatus = "Waiting List";
    }
    else if (element === "CN") {
      progStatus = "Cancelled";
    }
    else if (element === "SU") {
      progStatus = "Suspended";
    }
    return progStatus;
  }
}

