import { Component, OnInit, OnChanges, OnDestroy, Input, ViewChild, ViewContainerRef, ComponentFactoryResolver, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatPaginator, MatTableDataSource, MatSort} from '@angular/material';
import { ApplicationListService } from '../../../services/application-list.service';
import { IIwaApplication, IApplicationId } from '../../models/iwa-application.model';
import { ViewApplicationComponent } from './view-application/view-application.component';
import { IApplicationFile, Applicationfile } from '../../models/application-file.model';
import { IApplicationChoice, ApplicationProgChoice } from '../../models/application-choice';
import { BehaviorSubject } from 'rxjs';



@Component({
  selector: 'app-application-results',
  templateUrl: './application-results.component.html',
  styleUrls: ['./application-results.component.scss', '../applications.component.scss']
})
export class ApplicationResultsComponent implements OnInit, AfterViewInit {
  @Input() applicationSearch?: any;
  ELEMENT_DATA;
  data: any[];

  public loadSearchResult: boolean = false;
  public searchResultWindow: boolean = true;
  public searchButtonClicked = false;
  public filteredData: IIwaApplication[];
  public applicationsList: IApplicationId[];
  requestedApplicationDetails: IIwaApplication;
  applicationFilesMessage = '';
  applicationFilesFound = false;
  // private dataSource: any;
  currentFilteredData: any;
  SearchForm: FormGroup;
  ApplStatusList: string[];
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['ApplicationId'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(ViewApplicationComponent) applicationDetails: any;
  @ViewChild(ViewApplicationComponent) aplicationFilesFound: boolean;
  // @ViewChild('detailsContainer', { read: ViewContainerRef }) viewContainerRef: ViewContainerRef;

  
  
  constructor(private service: ApplicationListService,
    // private _resolver: ComponentFactoryResolver,
    private _formBuilder: FormBuilder) {

  }

  ngAfterViewInit() {
    // console.log('application-result.component AfterViewInit()');
  }
  // changeData(data: any) {
  //   console.log('changeData() called');
  //   this.dataSource.next(data);
  // }

  // ngOnDestroy(): void {
  //   //Called once, before the instance is destroyed.
  //   //Add 'implements OnDestroy' to the class.

  // }
  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit() {
    this.paginator.pageSize = 5;
    this.service.getApplicationStatusList()
      .subscribe((statusList: any) => {
        // console.log('service.getApplicationStatusList()');
        // console.log(statusList);
        this.ApplStatusList = statusList.appl_status;
      });
    this.SearchForm = this._formBuilder.group({
      appNumber: ['', null],
      appStatus: ['', null],
      appTerm: ['', null],
      appSubmitStatus: ['', null]
    });
    // console.log('application-result component OnInit() searchButtonClicked=' + this.searchButtonClicked);
    // if (!this.searchButtonClicked) {
    //   this.service.getAllApplications()
    //     .subscribe((listData: IIwaApplication[]) => {
    //       this.dataSource = new BehaviorSubject(listData);
    //       this.currentFilteredData = this.dataSource.asObservable();
    //       this.filteredData = listData;
    //     });
    //   this.searchButtonClicked = true;
    // }

    if (!this.searchButtonClicked) {
      this.service.getAllApplicationsList()
        .subscribe((listData: IApplicationId[]) => {
          // this.dataSource = new BehaviorSubject(listData);
          // this.currentFilteredData = this.dataSource.asObservable();
          this.applicationsList = listData;
        });
      this.searchButtonClicked = true;
    }
    // console.log('filtered', this.filteredData);
  }

  searchApplication(filterValue: any) {
    // console.log('Clicked "Search Button"');
    // console.log('Search App Number: ' + this.SearchForm.value.appNumber);
    if (this.SearchForm.value.appNumber === '' &&
      this.SearchForm.value.appStatus.selected === '' &&
      // this.SearchForm.value.appStatus === '' &&
      this.SearchForm.value.appTerm === '') {
      alert('Please eselect a search criteria.');
    } else {
      if (this.SearchForm.value.appNumber !== '') {
        console.log("from Search button")
        this.ELEMENT_DATA =  [ {Application_Id: this.SearchForm.value.appNumber} ];
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
        this.dataSource.paginator = this.paginator;
        this.loadRequestedApplication(this.SearchForm.value.appNumber);
        
      } else {
        this.loadSearchResults(this.SearchForm.value.appStatus, this.SearchForm.value.appTerm);
      }
    }
  }

  async loadSearchResults(searchStatus: string, searchTerm: string) {
    this.loadSearchResult = false;
    this.service.getFilteredApplicationsStatTerm(searchStatus, searchTerm)
      .subscribe((applicationListData: IApplicationId[]) => {
        this.ELEMENT_DATA = applicationListData;
        this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
        this.dataSource.paginator = this.paginator;

      });
  }
  async loadRequestedApplication(applicationId) {
    console.log('application-result.component.loadRequestedApplication() AppId: ' + applicationId);
    await this.service.getApplicationById(applicationId).subscribe(async (appData: IIwaApplication) => {
      // console.log('application-result.component.loadRequestedApplication() App Data: ');
      // console.log(appData);

      this.requestedApplicationDetails = appData;
      this.loadSearchResult = true;
      this.applicationSearch = appData;
      this.aplicationFilesFound = false;
      if (this.requestedApplicationDetails) {
        this.service.getApplicationProgChoices(applicationId)
          .subscribe((progChoices: IApplicationChoice[]) => {
            
            let idx = 1;
            this.applicationDetails.applicationProgChoices = new Array<IApplicationChoice>();
            if (Array.isArray(progChoices) && progChoices.length > 0) {
              progChoices.forEach(oneChoice => {
                // console.log(oneChoice);
                let tempChoice = new ApplicationProgChoice();
                tempChoice.Idx = idx;
                tempChoice.ChoiceNumber = oneChoice.ChoiceNumber;
                tempChoice.StartDate = oneChoice.StartDate;
                tempChoice.ProgramCode = oneChoice.ProgramCode;
                tempChoice.ProgramCampus = oneChoice.ProgramCampus;
                tempChoice.AcadPlan = oneChoice.AcadPlan;
                tempChoice.AcadCareer = oneChoice.AcadCareer;
                tempChoice.Strm = oneChoice.Strm;
                tempChoice.ActionReason = oneChoice.ActionReason;

                // console.log('Temp Choice: ');
                // console.log(tempChoice);
                this.applicationDetails.applicationProgChoices.push(tempChoice);
                idx++;
              });
            } else {
              this.applicationDetails.ProgChoiceMessage = 'No Program Choices Found for ' + applicationId;
            }
            // console.log(this.applicationDetails.applicationProgChoices);
          });
        // this.requestedApplicationDetails.ApplicationFiles = new Array<IApplicationFile>();
        this.service.getApplicationFiles(applicationId).subscribe((appFiles: IApplicationFile[]) => {
          // console.log('application-results.component getApplicationFiles() for: ' + applicationId);
          if (Array.isArray(appFiles) && appFiles.length > 0) {
            this.applicationDetails.applicationFiles = new Array<IApplicationFile>();
            // this.applicationDetails.ApplicationFiles = new Array<IApplicationFile>();
            this.aplicationFilesFound = true;
            appFiles.forEach(oneFile => {
              // console.log(oneFile);
              let tempFile = new Applicationfile();
              let fileType = oneFile.Document_Name.substring(oneFile.Document_Name.lastIndexOf('.') + 1);
              tempFile.Idx = oneFile.Idx;
              tempFile.Document_Type = oneFile.Document_Type;
              tempFile.Document_Id = oneFile.Document_Id;
              tempFile.Document_Name = oneFile.Document_Name;
              tempFile.Upload_Date = oneFile.Upload_Date;
              tempFile.FileType = fileType.toLowerCase();
              //console.log(oneFile);
              this.applicationDetails.applicationFiles.push(tempFile);
              // this.requestedApplicationDetails.ApplicationFiles.push(tempFile);
            });
            // this.requestedApplicationDetails.ApplicationFiles = appFiles;
            this.applicationFilesFound = true;
          } else {
            this.applicationDetails.applicationFiles = null;
            // this.requestedApplicationDetails.FilesMessage = 'No files found for Application ID: ' + applicationId;
          }

          // console.log(appFiles);
        });
      }
    });
    // console.log('application-result.component.loadRequestedApplication() App Details: ');
    // console.log(this.requestedApplicationDetails);


  }
}
