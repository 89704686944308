
<div class="image-view" *ngIf="data.fileType != 'pdf'; else pdfDiv">
  <p>
    Application ID: {{data.applicationId }},
    File ID: {{data.fileId}},
    File Type: {{data.fileType}}
  </p>
  <img [src]="data.fileContent" height="65%">
</div>
<ng-template #pdfDiv>
  <div class="pdf-container" height="65%">
    <!-- <a class="link" [href]="data.fileContentBase64">Click to view PDF file</a> -->

    <ngx-extended-pdf-viewer [src]="data.fileContentBase64"
    backgroundColor="#ffffff"
    [height]="'90vh'"
    [useBrowserLocale]="true"
    [handTool]="false"
    [showHandToolButton]="true"></ngx-extended-pdf-viewer>

    <!--
    <embed [src]="pdfUrl" type="application/pdf" width="100%" height="600px" />
    -->
    <!--
    <ejs-pdfviewer id="pdfViewer" [serviceUrl]='service' [documentPath]='pdfUrl' style="height:640px;display:block"></ejs-pdfviewer>
     <pdf-viewer [src]="pdfUrl" [original-size]="false" [render-text]="true"
    style="display: block;"></pdf-viewer>
    <div style="height: 600px">
      <ng2-pdfjs-viewer [pdfSrc]="data.fileContent"></ng2-pdfjs-viewer>
    </div>
  -->
  <!--
  <div style="height: 600px">
    <ng2-pdfjs-viewer [pdfSrc]="data.fileContent"></ng2-pdfjs-viewer>
  </div>
  -->
  <!-- displays blank page
  <ngx-extended-pdf-viewer
     [src]="data.fileReader"
     useBrowserLocale="true">
  </ngx-extended-pdf-viewer>
  -->
  <!-- displays blank page
      <pdf-viewer [src]="pdfUrl"
              style="display: block;"
              [original-size]="false"
              [fit-to-page]="true"
  ></pdf-viewer>
  -->
  <!-- displays blank page
  <object [data]="data.fileContent"
  type="application/pdf"
  width="100%"
  height="100%">
  <p>Your browser does not support PDFs.
    <a [href]="data.fileReader">Download the PDF</a>.</p>
  </object>
  -->
  <!--
  <app-image-viewer [images]="[data.fileContentBase64]" [idContainer]="'idOnHTML'"
  [loadOnInit]="true"></app-image-viewer>
  -->
  <!-- Document OK, No paging and scrolling
  <tld-file-viewer [src]="data.fileContentBase64" [type]="documentType" [pdfZoom]="0.4"></tld-file-viewer>
  -->
  </div>
</ng-template>



