<div class="content-container">
  <mat-card id="main-mat-card">
    <mat-card-title>
      <h2 class="card-title">Batch Control Configuration</h2>
    </mat-card-title>
    <br/>
    <div class="mat-content">
      <form #batchControlConf="ngForm" [formGroup]="BatchControlConfigForm" (ngSubmit)="updateBatchConf(BatchControlConfigForm.value)" class="form-container">
        <div *ngFor="let conf of batchControlData" id="batch-info">
          <mat-form-field id="batch_name" appearance="outline">
            <mat-label> Batch Name </mat-label>
            <input size="{{conf.BatchName.length}}" matInput placeholder="Batch Name" formControlName="BatchName_{{conf.Idx}}" [(ngModel)]="conf.BatchName" readonly />
          </mat-form-field>
          <mat-form-field id="batch_running" appearance="outline">
            <mat-label>Batch Running</mat-label>
            <mat-select placeholder="Batch Running" formControlName="BatchRunning_{{conf.Idx}}" [(ngModel)]="conf.BatchRunning">
                <mat-option *ngFor="let rOption of runningOptions" [value]="rOption.value"> {{ rOption.value }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field id="batch_start" appearance="outline">
            <mat-label>Batch Start</mat-label>
            <input matInput placeholder="Batch Start" formControlName="BatchStart_{{conf.Idx}}" [(ngModel)]="conf.BatchStart"/>
              <mat-error> <em
                  *ngIf="this?.touched && this?.invalid">
                  Required</em> </mat-error>
          </mat-form-field>
          <mat-form-field id="batch_end" appearance="outline">
            <mat-label>Batch End</mat-label>
            <input matInput placeholder="Batch End" formControlName="BatchEnd_{{conf.Idx}}" [(ngModel)]="conf.BatchEnd"/>
              <mat-error> <em
                  *ngIf="this?.touched && this?.invalid">
                  Required</em> </mat-error>
          </mat-form-field>
          <mat-form-field id="batch_end" appearance="outline">
            <mat-label>Batch Frequency</mat-label>
            <input matInput placeholder="Batch End" formControlName="BatchFrequency_{{conf.Idx}}" [(ngModel)]="conf.BatchFrequency"/>
              <mat-error> <em
                  *ngIf="this?.touched && this?.invalid">
                  Required</em> </mat-error>
          </mat-form-field>
        </div>
        <div class="actions-box">
          <mat-card-actions>
            <button mat-stroked-button type="button" class="seneca-button red" color="primary" (click)="goHome()">
              <span>Cancel</span>
            </button>

            <button mat-flat-button type="button" class="seneca-button" color="primary" (click)="invokeApplicationBatch()">
              <span>Submit Applications</span>
            </button>

            <button mat-flat-button type="button" class="seneca-button" color="primary" (click)="invokeDocumentsBatch()">
              <span>Submit Documents</span>
            </button>

            <button type="submit" mat-flat-button class="seneca-button" [disabled]="BatchControlConfigForm.invalid"
              color="primary">
              <span>Update</span>
            </button>
          </mat-card-actions>
        </div>
      </form>
      <mat-card-content class="mat-elevation-z8"> </mat-card-content>
    </div>
  </mat-card>
</div>
