<div class="seneca-list-container">
  <mat-card class="main-container">
    <mat-card-title>
      <h1 class="card-title">Country Restrictions</h1>
      <div class="actions-box">
        <mat-form-field appearance="outline" class="search-bar">
          <mat-label>Term</mat-label>
          <input matInput #search maxlength="4" placeholder="Term" (keyup.enter)="loadPrograms(search.value)" />
          <mat-hint align="start">Four digits </mat-hint>
        </mat-form-field>

        <mat-card-actions>          
          <button mat-flat-button type="button" class="seneca-button" color="primary"
            (click)="loadPrograms(search.value)">
            Load
          </button>
        </mat-card-actions>
      </div>
    </mat-card-title>
  </mat-card>
</div>
<app-country-restriction-list *ngIf="loadProgram" [term]="term"></app-country-restriction-list>