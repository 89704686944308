import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatPaginator, MatSort, MatTableDataSource, Sort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { CountryRestrictionService } from '../../../services/country-restriction.service';
import { AuthenticationService } from '../../../../shared/auth/auth.service';
import { DialogService } from '../../../services/dialog.service';


@Component({
  selector: 'app-country-restriction-new',
  templateUrl: './country-restriction-new.component.html',
  styleUrls: [
    '../../../../content/shared/css/commonList.css',
    '../../../../content/shared/css/commonAddNew.css',
    './country-restriction-new.component.scss'
  ],
  providers: [DialogService]
})
export class CountryRestrictionNewComponent implements OnInit {
  //id: number;
  ELEMENT_DATA: any[];
  selection;

  CountriesList: any[];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns: string[] = ['select', 'PROG_DESCR', 'ACAD_PROG', 'CAMPUS_DESC'];
  dataSource: MatTableDataSource<any>;

  selectedCountry: string;
  selectedTerm: string;
  currentUser: string;

  constructor(private activeRoute: ActivatedRoute, 
              private location: Location,
              private countryRestrictionService: CountryRestrictionService,
              private authService: AuthenticationService,
              private dialogService: DialogService) {
                this.currentUser = authService.getUserInfo().name;
              }

  ngOnInit() {
    // load countries list
    this.countryRestrictionService.getAllCountryList().subscribe(data => {
      this.CountriesList = data;
    }, error => {
      
    });
    
  }

  loadPrograms(term){
    // console.log('load programs called with: ' + term);
    // console.log('selected country is: ' + this.selectedCountry);
    this.selectedTerm = term;
    this.countryRestrictionService.getAcadPrograms(term).subscribe(data => {
      this.ELEMENT_DATA = data;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);

      this.dataSource.sort = this.sort;
      this.selection = new SelectionModel<any>(true, []);

      const sortState: Sort = { active: 'ACAD_PROG', direction: 'asc' };
      this.sort.active = sortState.active;
      this.sort.direction = sortState.direction;
      this.sort.sortChange.emit(sortState);

      this.paginator.pageSize = 10;

      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          default:
            return item[property] ? item[property].toLowerCase() : null;
        }
      };
      this.dataSource.paginator = this.paginator;

      //console.log(this.ELEMENT_DATA)
    }, error => {
      
    }
    );
  }

  addNewCountryRestrictions(){
    // console.log('selected country is: ' + this.selectedCountry);
    if(this.selectedCountry){

      let sel = this.selection.selected;
      let country = this.selectedCountry;
      let term = this.selectedTerm;
      let user = this.currentUser;
      let AcademicProgramIds = [];

      sel.forEach(function (value) {
        AcademicProgramIds.push({
          COUNTRY_CODE: country,
          ACAD_PROG: value.ACAD_PROG,
          CAMPUS: value.CAMPUS,
          STRM: term,
          createdBy: user
        });
      });

      // call the API to Add the selected IDs
      // console.log(AcademicProgramIds);
      this.countryRestrictionService.addCountryrestrictions(AcademicProgramIds).subscribe(res => {

        this.dialogService.MessageBox('Records added', 'X', 2000, 'SuccessMessage');

      }, error => {
        this.dialogService.MessageBox('Error updating record', 'X', 5000, 'ErrorMessage');
      }
      );
    }
    else{
      this.dialogService.MessageBox('Please select a country', 'X', 5000, 'ErrorMessage');
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    try{
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
    }
    catch(e){
      return false;
    }
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  selectionHasValue(){
    if(!this.selection === undefined){
      return this.selection.hasValue();
    }
    else{
      return false;
    }
  }

  logDebug(e?) {
    console.log('e', e);
  }

  goBack() {
    this.location.back();
  }
  abc() {}
}
