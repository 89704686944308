<div class="seneca-list-container">
  <mat-card class="main-container">
    <mat-card-title>
      <h2 class="card-title">Term: {{term}}</h2>
      

    </mat-card-title>
    <mat-card-content>
      <div class="actions-box">
        <mat-card-actions>
          <button mat-stroked-button class="seneca-button seneca-stroke-button" color="primary" (click)="deleteCountryRestrictions()">
            <mat-icon>delete</mat-icon> <span>Delete Selection</span>
          </button>
    
          <button mat-flat-button class="seneca-button" color="primary" [routerLink]="['../0']">
            <mat-icon>add</mat-icon> <span>Add New</span>
          </button>
          
          <!-- <button class="seneca-button" mat-stroked-button [routerLink]="['../preview']"> -->
          <!-- <button class="seneca-button" mat-stroked-button>
            <mat-icon class="is-blue">remove_red_eye</mat-icon> <span>Preview</span>
          </button> -->
        </mat-card-actions>

        <mat-form-field appearance="outline" class="search-bar">
          <mat-label>Search</mat-label>
          <input matInput #search (keyup)="applyFilter($event.target.value)" maxlength="256" placeholder="Search" />
          <mat-icon matSuffix>search</mat-icon>

          <mat-hint align="start">Example: Term, Country </mat-hint>
        </mat-form-field>

      </div>
    

    <div class="seneca-table-container seneca-elevation">
      <table mat-table [dataSource]="dataSource" matSort>
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selectionHasValue() && isAllSelected()"
              [indeterminate]="selectionHasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation();"
              (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"
              [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="Country_Name">
          <th class="priority-1" mat-header-cell *matHeaderCellDef mat-sort-header>Country</th>
          <td class="priority-1" mat-cell *matCellDef="let element">{{ element.Country_Name }}</td>
        </ng-container>

        <ng-container matColumnDef="STRM">
          <th class="priority-2" mat-header-cell *matHeaderCellDef mat-sort-header>Term</th>
          <td class="priority-2" mat-cell *matCellDef="let element">{{element.STRM}}</td>
        </ng-container>

        <ng-container matColumnDef="PROG_DESCR">
          <th class="priority-3" mat-header-cell *matHeaderCellDef mat-sort-header>Program Name</th>
          <td class="priority-3" mat-cell *matCellDef="let element">{{ element.PROG_DESCR }}</td>
        </ng-container>

        <ng-container matColumnDef="ACAD_PROG">
          <th class="priority-4" mat-header-cell *matHeaderCellDef mat-sort-header>Code</th>
          <td class="priority-4" mat-cell *matCellDef="let element">{{ element.ACAD_PROG }}</td>
        </ng-container>

        <ng-container matColumnDef="CampusName">
          <th class="priority-5" mat-header-cell *matHeaderCellDef mat-sort-header>Campus</th>
          <td class="priority-5" mat-cell *matCellDef="let element">{{ element.CampusName }}</td>
        </ng-container>

        <ng-container matColumnDef="CreatedBy">
          <th class="priority-6" mat-header-cell *matHeaderCellDef mat-sort-header>Created By</th>
          <td class="priority-6" mat-cell *matCellDef="let element">{{ element.CreatedBy }}</td>
        </ng-container>


        <ng-container matColumnDef="CreatedDate">
          <th class="priority-7" mat-header-cell *matHeaderCellDef mat-sort-header>Created On</th>
          <td class="priority-7" mat-cell *matCellDef="let element">{{ element.CreatedDate |  date: 'mediumDate' }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[5, 10, 20, 50, 100]" showFirstLastButtons> </mat-paginator>
    </div>
  </mat-card-content>
</mat-card>
</div>