import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
//-----------date formater----------------------//
import { DateAdapter, MAT_DATE_LOCALE, MatDatepickerModule, MatDialog } from '@angular/material';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import * as _moment from 'moment';
// import { DialogService } from '../../../services/dialog.service';
// import { MatDialog } from '@angular/material';
import { MaterialModule } from '../../../../shared/material.module';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { PromoCodeService } from '../../../services/promocode.service';
import { AuthenticationService } from '../../../../shared/auth/auth.service';
import { DataRowOutlet } from '@angular/cdk/table';
import { DialogService } from '../../../services/dialog.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: 'YYYY-MM-DD', // NOTE: Make sure the DATE FORMAT from the database is the same
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY'
  }
};
// import { DialogModel, DialogComponent } from '../../../shared/dialog/dialog.component';

@Component({
  selector: 'app-promo-code-details',
  templateUrl: './promo-code-details.component.html',
  styleUrls: ['./promo-code-details.component.scss', '../../../../content/shared/css/commonAddNew.css'],

  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ]
})
export class PromoCodeDetailsComponent implements OnInit {
  id: number;
  promoCodeData;
  subscriber$;
  promoCode: string;
  PromoCodeForm: FormGroup = this._formBuilder.group({
    promoCode: '',
    promoCodeLimit: '',
    promoCodeUsage: '',
    description: '',
    startDate: new Date(),
    endDate: '',
    newCode: '',
    modifiedBy: '',
    modifiedDate: new Date()
  });
  tDate = new FormControl(_moment());
  currentUser: string;


  constructor(
    private _formBuilder: FormBuilder,
    private activeRoute: ActivatedRoute,
    private service: PromoCodeService,
    private location: Location,
    private datePicker: MatDatepickerModule,
    public dialog: MatDialog,
    private authService: AuthenticationService,
    private dialogService: DialogService
  ) {
    this.currentUser = authService.getUserInfo().name;;
  }

  ngOnInit() {
    console.log('promo-code-details.onInit() ActivatedRoute: ' + this.activeRoute);
    console.log('promoCode User: ' + this.currentUser);
    // this.id = parseInt(this.activeRoute.snapshot.paramMap.get('id'), 10);
    this.activeRoute.paramMap.subscribe(params => {
      // console.log(params.get('id'));
      if (params.get('id') !== '0') {
        this.promoCode = params.get('id');
        this.subscriber$ = this.service.getPromoCodeById(this.promoCode).subscribe(promoCodeDetails => {
          this.promoCodeData = promoCodeDetails;
          console.log('from database', this.promoCodeData);
          this.PromoCodeForm.patchValue({
            promoCode: [this.promoCode, Validators.required],
            promoCodeLimit: [this.promoCodeData.PromoCodeLimit, Validators.required],
            promoCodeUsage: [this.promoCodeData.PromoCodeUsage, Validators.required],
            description: [this.promoCodeData.Description, Validators.required],
            startDate: [this.promoCodeData.StartDate, Validators.required],
            endDate: [this.promoCodeData.StopDate, Validators.required],
            newCode: this.promoCodeData.NewCode,
            modifiedBy: this.currentUser,
            modifiedDate: new Date()
          });
        });
      } else {
        this.promoCode = 'SENE' + Math.random().toString().slice(2).slice(0, 10);
        // console.log('New Promo Code: ' + this.promoCode);
        this.promoCodeData = {
          PromoCode: this.promoCode,
          PromoCodeLimit: '',
          PromoCodeUsage: 0,
          Description: '',
          StartDate: new Date(),
          StopDate: new Date(),
          NewCode: 'Y',
          ModifiedBy: this.currentUser,
          ModifiedDate: new Date()
        };
        this.PromoCodeForm.patchValue({
          promoCode: this.promoCode,
          promoCodeLimit: ['', Validators.required],
          promoCodeUsage: [0, Validators.required],
          description: ['', Validators.required],
          startDate: [new Date(), Validators.required],
          endDate: ['', Validators.required],
          newCode: 'Y',
          modifiedBy: this.currentUser,
          modifiedDate: new Date()
        });
      }
    });
  }

  goBack() {
    // this.subscriber$.unsubscribe();
    this.location.back();
  }



  savePromoCodeDetails(formValue) {
    //Check to make sure end date is not before start date
    let end = new Date(this.promoCodeData.StopDate);
    let start = new Date(this.promoCodeData.StartDate);
    if (start.getTime() > end.getTime()) {
      this.dialogService.ErrorDialog("Date Error","End date must not be before start date.","Close","Okay");
    }
    else {
      if (formValue.newCode === 'N') {
        this.service.updatePromoCode(formValue, formValue.promoCode[0])
          .subscribe(res => {
            console.log(res);
            if (res === 1) {
              //alert('SUCCESS - Promo Code ' + formValue.promoCode[0] + ' updated');
              this.dialogService.MessageBox('SUCCESS - Promo Code ' + formValue.promoCode[0] + ' updated', 'X', 2000, 'SuccessMessage');
              this.goBack();
            }
          }, err => {
            console.log(err);
          });
      } else {
        this.service.createPromoCode(formValue)
          .subscribe(res => {
            console.log(res);
            if (res === 1) {
              //alert('SUCCESS - Promo Code ' + formValue.promoCode + ' created');
              this.dialogService.MessageBox('SUCCESS - Promo Code ' + formValue.promoCode + ' created', 'X', 2000, 'SuccessMessage');
              this.goBack();
            }
          }, err => {
            console.log(err);
          });
      }
    }
  }
}
