<mat-card>
    <mat-card-header>
         <mat-card-title>
            Add a Comment
        </mat-card-title>
        <mat-card-subtitle>
            Please add a comment explaining why the commissions were rejected:
        </mat-card-subtitle>
    </mat-card-header>
   <mat-card-content>
    <mat-form-field appearance="fill">
    <mat-label>Comment</mat-label>
    <textarea matInput [(ngModel)]="data.comment" required></textarea>
  </mat-form-field>
   </mat-card-content>
   <mat-card-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button [mat-dialog-close]="data.comment" cdkFocusInitial>Ok</button>
   </mat-card-actions>
</mat-card>
  
