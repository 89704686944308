import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

export interface DialogData {
    comment:string;
  }
  
  @Component({
    selector: 'commentDialog',
    templateUrl: 'commentDialog.html',
    styleUrls: ['./commentDialog.scss']
  })
  export class addCommentDialog {
    constructor(
      public dialogRef: MatDialogRef<addCommentDialog>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {}
  
    onNoClick(): void {
      this.dialogRef.close();
    }
  }